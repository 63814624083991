import React from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import { Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useUpdatePasswordMutation } from "../redux/apis/user";

const ChangePassword = () => {
  const validationSchema = object({
    currentPassword: string()
      .required("No Current password provided")
      .min(8, "Password is too short - should be 8 characters minimum"),
    newPassword: string()
      .required("No New password provided")
      .min(8, "Password is too short - should be 8 characters minimum"),
  });

  const [update, { isLoading }] = useUpdatePasswordMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      await update(values);
      toast.success("Your password is updated");
      navigate("/profile");
    } catch (err) {
      console.log(err);
      toast.error(err.data.message);
    }
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Changed Password</Typography>
      </Stack>

      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-3 flex flex-col gap-2">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                name="currentPassword"
                className="inputC md:w-[25%]"
                placeholder="please enter your current password"
                value={values.currentPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <p className="text-rose-500 text-sm mt-2">
              {errors.currentPassword &&
                touched.currentPassword &&
                errors.currentPassword}
            </p>

            <div className="mt-3 flex flex-col gap-2">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                name="newPassword"
                className="inputC md:w-[25%]"
                placeholder="please enter your new password"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <p className="text-rose-500 text-sm mt-2">
              {errors.newPassword && touched.newPassword && errors.newPassword}
            </p>

            <button
              disabled={isLoading}
              type="submit"
              className="btn bg-primary w-[25%] mt-3"
            >
              Update
            </button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ChangePassword;
