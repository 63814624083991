import React from "react";
import ContactImg from "../assets/images/contactus.jpg";
import Image1 from "../assets/images/image-1.jpg";
import Image2 from "../assets/images/image-2.jpg";
import "../assets/styles/contactus.css";

import img from "../assets/images/image7.png";

import img1 from "../assets/images/image8.png";
import img3 from "../assets/images/image9.png";

export default function ContactUs() {
  return (
    <div className="main">
      <div className="contactus-banner">
        <img src={img} alt="contactus-page-banner" />
        <div className="banner-text">
          <h1>Contact Us</h1>
          <p>keep in touch with us</p>
        </div>
      </div>

      <section className="contact-section">
        <div className="contact-panel contact-panel-1">
          <div className="contact-info">
            <h2>Contact Information</h2>
            <p>
              Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod
              dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu,
              dapibus eu, fermentum et, dapibus sed, urna.
            </p>
          </div>
          <div className="office-info">
            <div className="office-info-p1">
              <h4>The Office</h4>
              <div className="info">
                <span className="icon-wrapper">
                  <i className="fa-solid fa-location-dot"></i>
                </span>
                <p>Sector 8, Rohini New Delhi, Delhi 110085, India</p>
              </div>
              <div className="info">
                <span className="icon-wrapper">
                  <i className="fa-solid fa-phone"></i>
                </span>
                <a href="#!">+91 1234567854</a>
              </div>
              <div className="info">
                <span className="icon-wrapper">
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <a href="#!">info@molla.com</a>
              </div>
            </div>
            <div className="office-info-p2">
              <h4>The Office</h4>
              <div className="info">
                <span className="icon-wrapper">
                  <i className="fa-regular fa-clock"></i>
                </span>
                <p>
                  Monday-Saturday <br />
                  11am-7pm IST
                </p>
              </div>
              <div className="info">
                <span className="icon-wrapper">
                  <i className="fa-regular fa-calendar-days"></i>
                </span>
                <p>
                  Sunday <br />
                  11am-6pm ET
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-panel contact-panel-2">
          <div className="contact-info">
            <h2>Got Any Questions?</h2>
            <p>Use the form below to get in touch with the sales team</p>
          </div>
          <div className="query-form-container">
            <form className="query-form">
              <div className="inputs-container">
                <input
                  type="text"
                  className="input-element"
                  placeholder="Name *"
                />
                <input
                  type="text"
                  className="input-element"
                  placeholder="Email *"
                />
                <input
                  type="text"
                  className="input-element"
                  placeholder="Phone"
                />
                <input
                  type="text"
                  className="input-element"
                  placeholder="Subject"
                />
              </div>

              <div className="textarea-container">
                <textarea
                  className="input-element textarea-element"
                  cols="40"
                  rows="10"
                  placeholder="Message *"
                ></textarea>
              </div>

              <button type="submit" className="submit-btn">
                SUBMIT
                <span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </span>
              </button>
            </form>
          </div>
        </div>
      </section>

      <section className="our-stores-section">
        <h1 className="stores-title">Our Stores</h1>
        <div className="stores-container">
          <div className="store">
            <img src={img1} alt="store" />
            <div className="store-info">
              <div className="store-address">
                <div className="storename">Sector 8</div>
                <p>Rohini New Delhi, Delhi 110085, India</p>
                <a href="#!" className="phone-link">
                  +91 987-876-6543
                </a>
              </div>

              <div className="store-hours">
                <p className="title">Store Hours:</p>
                <p>Monday - Saturday 11am to 7pm</p>
                <p>Sunday 11am to 6pm</p>
              </div>

              <a href="#!" className="viewmap-link">
                View Map
                <span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="store">
            <img src={img3} alt="store" />
            <div className="store-info">
              <div className="store-address">
                <div className="storename">Sector 8</div>
                <p>Rohini New Delhi, Delhi 110085, India</p>
                <a href="#!" className="phone-link">
                  +91 987-876-6543
                </a>
              </div>

              <div className="store-hours">
                <p className="title">Store Hours:</p>
                <p>Monday - Friday 9am to 8pm</p>
                <p>Saturday - 9am to 2pm</p>
                <p>Sunday - Closed</p>
              </div>

              <a href="#!" className="viewmap-link">
                View Map
                <span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
