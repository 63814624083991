import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import Alert from "../Components/util/Alertmessage";
import { resetPassword, resetSuccess } from "../redux/reducers/user.reducer";

export default function ResetPassword() {
  const validationSchema = object({
    password: string()
      .required("No password provided")
      .min(8, "Password is too short - should be 8 characters minimum"),
  });
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { success, resetError } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (success) {
      dispatch(resetSuccess());
      navigate("/login");
    }
  }, [success, navigate, dispatch]);

  const handleSubmit = (values) => {
    console.log("onsubmit values on login", values, token);
    dispatch(resetPassword({ ...values, token }));
  };
  return (
    <>
      <div className="login">
        <div className="loginBox">
          <h3>Forgot Password</h3>
          {resetError.length ? <Alert msg={resetError} severity="error" /> : ""}
          <Formik
            initialValues={{ password: "", confirmpassword: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mt-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="loginInput"
                    placeholder="please enter your new password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.password && touched.password && errors.password}
                </p>
                <div className="mt-3">
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmpassword"
                    className="loginInput"
                    placeholder="please enter the confirm password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmpassword}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.confirmpassword &&
                    touched.confirmpassword &&
                    errors.confirmpassword}
                </p>
                <div className="flex flex-col">
                  <Button
                    sx={{ mt: 3 }}
                    variant="outlined"
                    size="large"
                    className="shopNowbtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                  <Link to="/login" className="link text-center">
                    Cancel
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
