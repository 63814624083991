import React from "react";
import { useSelector } from "react-redux";
import ProductCarousel from "./CardCarousel";
import { Link } from "react-router-dom";
import { getURL } from "../utils";

export default function BrandsCarousel() {
  const { brands } = useSelector((state) => state.brandReducer);

  return (
    <div className="brandscarousel">
      <h3 className="subheading">Shop By Brands</h3>
      <p className="subdescription">
        The World's Premium Brands In One Destination.
      </p>
      <ProductCarousel
        datas={brands}
        Component={ImageComponent}
        defaultslides={5}
        responsive={[
          {
            breakpoint: 1528,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1290,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 910,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 593,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      />
    </div>
  );
}

function ImageComponent({ data }) {
  return (
    <Link to={`/search?brand=${data._id}`}>
      <img
        className="brandImage"
        src={`${getURL()}${data.logo}`}
        alt="loading..."
      />
    </Link>
  );
}
