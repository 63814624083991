import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import moment from "moment/moment";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils";
import { capital } from "../table/utils";
import Loader from "../util/Loader";
export default function OrderRow({
  selected,
  username,
  isAdmin,
  orderId,
  status,
  price,
  items,
  date,
  handleClick,
  handleReorder,
  isLoading,
}) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell>{moment(date).format("MMMM Do YYYY, h:mm:ss a")}</TableCell>
        <TableCell>{orderId}</TableCell>

        <TableCell>{formatPrice(price)}</TableCell>

        <TableCell>
          <span>{capital(status)}</span>
        </TableCell>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <TableCell>
            <div className="">
              <Link to={`/profile/orders/${orderId}`}>
                <button className="btn text-sm">View Details</button>
              </Link>
            </div>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
