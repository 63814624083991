import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import React from "react";
import Scrollbar from "../scrollbar/Scrollbar";
import Head from "../table/Head";
import OrderRow from "./OrdersRow";
import TableEmptyRows from "../table/EmptyRow";
import { emptyRows } from "../table/utils";
import TableNoData from "../table/NoData";
import { useTable } from "../../hooks/useTable";
import { useReorderItemsMutation } from "../../redux/apis/order";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getCartCount, getCartItems } from "../../redux/reducers/cart.reducer";

const OrdersList = ({ data, isAdmin }) => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
    handleSelectAllClick,
    handleSort,
    notFound,
    selected,
    filterName,
    page,
    rowsPerPage,
    order,
    orderBy,
    dataFiltered,
  } = useTable(data);

  const dispatch = useDispatch();

  const [reorder, { isLoading }] = useReorderItemsMutation();

  const handleReorder = async (orderId) => {
    const confirmed = window.confirm(
      "Are you sure to reorder this order again?"
    );
    if (!confirmed) return;

    try {
      await reorder(orderId).unwrap();
      dispatch(getCartCount());
      dispatch(getCartItems());
      toast.success("Products added to cart successfully");
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  const labels = [
    { id: "date", label: "Date" },
    { id: "orderId", label: "Order ID" },
    { id: "price", label: "Total Price" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <Head
              order={order}
              orderBy={orderBy}
              rowCount={data.length}
              numSelected={selected.length}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={labels}
            />
            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <OrderRow
                    key={row._id}
                    isAdmin={isAdmin}
                    username={row.userName}
                    orderId={row._id}
                    date={row.createdAt}
                    status={row.status}
                    price={row.total}
                    items={row.productOrdered}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                    handleReorder={handleReorder}
                    isLoading={isLoading}
                  />
                ))}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, data.length)}
              />

              {notFound && <TableNoData query={filterName} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default OrdersList;
