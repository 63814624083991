import React from "react";
import AboutUsImg from "../assets/images/aboutus.png";
import Brand1 from "../assets/images/brand-img-1.png";
import Brand2 from "../assets/images/brand-img-2.png";
import Brand3 from "../assets/images/brand-img-3.png";
import Brand4 from "../assets/images/brand-img-4.png";
import Brand5 from "../assets/images/brand-img-5.png";
import Brand6 from "../assets/images/brand-img-6.png";
import Brand7 from "../assets/images/brand-img-7.png";
import Brand8 from "../assets/images/brand-img-8.png";
import Brand9 from "../assets/images/brand-img-9.png";
import Member1 from "../assets/images/member-1.jpg";
import Member2 from "../assets/images/member-2.jpg";
import Member3 from "../assets/images/member-3.jpg";
import Aboutheaderbg from "../assets/images/about-header-bg.jpg";

import img from "../assets/images/image.png";

import img1 from "../assets/images/image1.png";
import img2 from "../assets/images/image3.png";

export default function About() {
  return (
    <>
      <main className="main middle-component">
        <div className="aboutus-banner">
          <img className="h-[90vh] w-full" src={img} alt="about-header-bg" />
          <div className="banner-text">
            <h1>About Us</h1>
            <p>Who We Are</p>
          </div>
        </div>

        <section className="vision-and-mission-section">
          <div className="about-child">
            <h3>Our Vision</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi
              neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium,
              ligula sollicitudin laoreet viverra, tortor libero sodales leo,
              eget blandit nunc tortor eu nibh.
            </p>
          </div>
          <div className="about-child">
            <h3>Our Mission</h3>
            <p>
              Sed egestas, ante et vulputate volutpat, eros pede semper est,
              vitae luctus metus libero eu augue. Morbi purus libero, faucibus
              adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
              elementum hendrerit tortor. Sed semper lorem at felis.
            </p>
          </div>
        </section>
      </main>
      <section className="whoWeAre-section">
        <div className="middle-component">
          <div className="child-component">
            <h3>Who We Are</h3>
            <h4>
              Pellentesque odio nisi, euismod pharetra a ultricies in diam. Sed
              arcu. Cras consequat
            </h4>
            <p>
              Sed pretium, ligula sollicitudin laoreet viverra, tortor libero
              sodales leo, eget blandit nunc tortor eu nibh. Suspendisse
              potenti. Sed egestas, ante et vulputate volutpat, uctus metus
              libero eu augue.
            </p>

            <a href="#!" className="news-link">
              VIEW OUR NEWS
              <span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </span>
            </a>
          </div>
          <div className="image-container">
            <img src={img1} alt="aboutUs" />
          </div>
        </div>
      </section>

      <section className="brands-section">
        <div className="middle-component">
          <div className="brands-section-about">
            <h3>The world's premium design brands in one destination.</h3>
            <p>
              Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi
              neque, aliquet vel, dapibus id, mattis vel, nis
            </p>
          </div>

          <div className="brands-container">
            <div className="brand-image">
              <img src={Brand1} alt="brand-1" />
            </div>
            <div className="brand-image">
              <img src={Brand2} alt="brand-2" />
            </div>
            <div className="brand-image">
              <img src={Brand3} alt="brand-3" />
            </div>
            <div className="brand-image">
              <img src={Brand4} alt="brand-4" />
            </div>
            <div className="brand-image">
              <img src={Brand5} alt="brand-5" />
            </div>
            <div className="brand-image">
              <img src={Brand6} alt="brand-6" />
            </div>
            <div className="brand-image">
              <img src={Brand7} alt="brand-7" />
            </div>
            <div className="brand-image">
              <img src={Brand8} alt="brand-8" />
            </div>
            <div className="brand-image">
              <img src={Brand9} alt="brand-9" />
            </div>
          </div>
        </div>
      </section>

      <section className="team-section">
        <div className="middle-component">
          <h1 className="team-section-heading">Meet Our Team</h1>
          <div className="members-container">
            <div className="member">
              <div className="member-image-container">
                <img src={Member1} alt="member" />
                <figcaption className="member-overlay">
                  <h4 className="member-title">
                    Samanta Grey <br />
                    <span>Founder & CEO</span>
                  </h4>

                  <p className="about-member">
                    Sed pretium, ligula sollicitudin viverra, tortor libero
                    sodales leo, eget blandit nunc.
                  </p>
                </figcaption>
              </div>
              <div className="member-name">
                <h4>Samanta Grey</h4>
                <p>Founded & CEO</p>
              </div>
            </div>
            <div className="member">
              <div className="member-image-container">
                <img src={Member2} alt="member" />
                <figcaption className="member-overlay">
                  <h4 className="member-title">
                    Bruce Sutton <br />
                    <span>Sales & Marketing Manager</span>
                  </h4>

                  <p className="about-member">
                    Sed pretium, ligula sollicitudin viverra, tortor libero
                    sodales leo, eget blandit nunc.
                  </p>
                </figcaption>
              </div>
              <div className="member-name">
                <h4>Bruce Sutton</h4>
                <p>Sales & Marketing Manager</p>
              </div>
            </div>
            <div className="member">
              <div className="member-image-container">
                <img src={Member3} alt="member" />
                <figcaption className="member-overlay">
                  <h4 className="member-title">
                    Janet Joy <br />
                    <span>Product Manager</span>
                  </h4>

                  <p className="about-member">
                    Sed pretium, ligula sollicitudin viverra, tortor libero
                    sodales leo, eget blandit nunc.
                  </p>
                </figcaption>
              </div>
              <div className="member-name">
                <h4>Janet Joy</h4>
                <p>Product Manager</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
