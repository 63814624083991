import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
  cart: [],
  totalQuantity: 0,
  totalPrice: 0,
  count: 0,
};

export const addtoCart = createAsyncThunk(
  "cart/add",
  async (product, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/checkout/add-one", product);

      return data.data;
    } catch (error) {
      console.log("error on add to cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCartItems = createAsyncThunk(
  "cart/get",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Client.get("/checkout/get");

      return data.data.checkout;
    } catch (error) {
      console.log("error on get cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCart = createAsyncThunk(
  "cart/udpate",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/checkout/edit", payload);

      return data.data;
    } catch (error) {
      console.log("error on get cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCartCount = createAsyncThunk(
  "cart/get/count",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Client.get("/checkout/count");

      return data.data.checkoutCount;
    } catch (error) {
      console.log("error on get cart count", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const cart1Slice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addItem(state, action) {
      const newItemId = action.payload.id;
      const existingItem = state.cart.find((item) => item.id === newItemId);

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.push({
          ...action.payload,
          quantity: 1,
        });
        state.count += 1;
      }

      state.totalQuantity += 1;
    },

    removeItem(state, action) {
      const id = action.payload.id;
      const existingItem = state.cart.find((item) => item.id === id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.totalQuantity -= existingItem.quantity;
          state.cart = state.cart.filter((item) => item.id !== id);
          state.count -= 1;
        }
      }
    },

    getTotal(state) {
      let { total, quantity } = state.cart.reduce(
        (cartTotal, cartItem) => {
          const { price, quantity } = cartItem;
          const itemTotal = price * quantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += quantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      state.totalPrice = total;
      state.totalQuantity = quantity;
    },

    setCount(state, action) {
      state.count = action.payload;
    },
  },
    extraReducers: (builder) => {
    builder.addCase(addtoCart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
      state.cartcount += 1;
    });
    builder.addCase(addtoCart.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(addtoCart.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(updateCart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateCart.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateCart.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      state.loading = false;
      state.cartData = action.payload;
    });
    builder.addCase(getCartItems.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getCartItems.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getCartCount.fulfilled, (state, action) => {
      state.loading = false;
      state.cartcount = action.payload;
    });
    builder.addCase(getCartCount.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getCartCount.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
  },
});

export const { addItem, getLength, removeItem, getTotal, setCount } =
  cart1Slice.actions;

export default cart1Slice.reducer;
