import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Main from "../Components/util/main";
import UserSidebar from "../Components/Sidebar";
import { useResponsive } from "../hooks/useResponsive";
import { useSelector } from "react-redux";
import { capital } from "../Components/table/utils";

const ProfileLayout = () => {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

  const lgUp = useResponsive("up", "lg");

  const { isLoggedIn, user } = useSelector((state) => state.userReducer);

  if (!isLoggedIn) {
    navigate("/");
  }

  return (
    <div style={{ backgroundColor: "rgb(249, 250, 251)" }}>
      {!lgUp && (
        <div className="w-full">
          {/* <IconButton onClick={() => setOpenNav(true)} sx={{ marginLeft: 2 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton> */}
          <Button
            onClick={() => setOpenNav(true)}
            variant="outlined"
            sx={{ marginTop: 2, marginLeft: 2 }}
          >
            Open Menu
          </Button>
        </div>
      )}
      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <UserSidebar
          openNav={openNav}
          onCloseNav={() => setOpenNav(false)}
          name={isLoggedIn ? capital(user.name) : ""}
        />
        <Main>
          <Outlet />
        </Main>
      </Box>
    </div>
  );
};

export default ProfileLayout;
