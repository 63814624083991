import React from "react";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE } from "../utils/data";
import { useRemoveWishlistMutation } from "../redux/apis/wishlist";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addtoCart } from "../redux/reducers/cart.reducer";
import { formatPrice } from "../utils";

const WishlistCard = ({ product }) => {
  const [remove, { isLoading }] = useRemoveWishlistMutation();
  const dispatch = useDispatch();

  const handleCart = async (e, id) => {
    e.preventDefault();
    const temp = {
      id: product._id,
      image: product.image[0],
      name: product.name,
      price: product.CurrentPrice,
      quantity: 1,
    };
    dispatch(addtoCart(temp));
    try {
      await remove(id).unwrap();
      toast.success("Product removed successfully");
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  const handleRemove = async (e, id) => {
    e.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to remove this product from the list?"
    );

    if (!confirmed) return;

    try {
      await remove(id).unwrap();
      toast.success("Product removed successfully");
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  let imageUrl;
  if (
    product.hasOwnProperty("image") &&
    Array.isArray(product.image) &&
    product.image.length > 0
  ) {
    imageUrl = `${BASE}/${product.image[0]}`;
  } else {
    imageUrl = "";
  }

  return (
    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
      <Link to={`/product/${product._id}`}>
        <img
          src={`${imageUrl}`}
          alt={product.name}
          className="h-72 w-72 object-cover rounded-t-xl"
        />
        <div className="px-4 py-3 w-72">
          <span className="text-gray-400 mr-3 uppercase text-xs">
            {product.brand_name}
          </span>
          <p className="text-lg font-bold text-black truncate block capitalize">
            {product.name}
          </p>
          <div className="flex items-center">
            <p className="text-lg font-bold text-darkblue cursor-auto my-3">
              {formatPrice(product.CurrentPrice)}
            </p>
            <del>
              <p className="text-sm text-gray-600 cursor-auto ml-2">
                {formatPrice(product.MRP)}
              </p>
            </del>
          </div>

          <div className="flex justify-between">
            <button
              onClick={(e) => handleCart(e, product._id)}
              className="bg-primary text-white p-2 rounded-md"
            >
              <AddShoppingCartIcon />
            </button>

            <button
              onClick={(e) => handleRemove(e, product._id)}
              className="bg-darkblue text-white p-2 rounded-md"
              disabled={isLoading}
            >
              <DeleteIcon />
              Remove
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default WishlistCard;
