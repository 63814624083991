import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const brandAPI = createApi({
  reducerPath: "brand",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: () => "/brand/get-all",
      providesTags: ["Brands"],
      transformResponse: (response) => {
        return response.data.data.map((val) => ({
          id: val._id,
          name: val.name,
        }));
      },
    }),

    createBrand: builder.mutation({
      query: (brand) => ({
        url: "brand/create",
        method: "POST",
        body: brand,
      }),
      invalidatesTags: ["Brands"],
    }),
  }),
});

export const { useGetBrandsQuery, useCreateBrandMutation } = brandAPI;
