import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ProfileForm from "../Components/profile/ProfileForm";
import { useSelector } from "react-redux";
import { useGetAddressQuery } from "../redux/apis/user";
import AddressForm from "../Components/profile/AddressForm";
import { useSearchParams } from "react-router-dom";
import { Alert } from "@mui/material";
import Loader from "../Components/util/Loader";

const Address = () => {
  const { data, isLoading, isError } = useGetAddressQuery();

  const [params] = useSearchParams();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  const checkout = Boolean(params.get("checkout"));

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Address</Typography>

        {checkout && (
          <Alert severity="error">Please fill out the address first.</Alert>
        )}
      </Stack>
      <AddressForm address={data.data} error={checkout} />
    </Container>
  );
};

export default Address;
