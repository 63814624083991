import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import productReducer from "./reducers/product.reducer";
import offerReducer from "./reducers/offer.reducer";
import brandReducer from "./reducers/brand.reducer";
// import adminReducer from "./reducers/admin.reducer";
import { categoryAPI } from "./apis/category";
import { brandAPI } from "./apis/brand";
import { productAPI } from "./apis/product";
import { orderAPI } from "./apis/order";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userAPI } from "./apis/user";
import { wishlistAPI } from "./apis/wishlist";
import categoryReducer from "./reducers/category.reducer";
import cartReducer from "./reducers/cart.reducer";
import cart1Reducer from "./reducers/cart1.reducer";
import { cartAPI } from "./apis/cart";
import { reviewAPI } from "./apis/review";
import { couponAPI } from "./apis/coupon";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  userReducer,
  productReducer,
  offerReducer,
  brandReducer,
  categoryReducer,
  cartReducer,
  // cart1Reducer,
  [categoryAPI.reducerPath]: categoryAPI.reducer,
  [brandAPI.reducerPath]: brandAPI.reducer,
  [productAPI.reducerPath]: productAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [wishlistAPI.reducerPath]: wishlistAPI.reducer,
  [reviewAPI.reducerPath]: reviewAPI.reducer,
  [couponAPI.reducerPath]: couponAPI.reducer,
  //   [cartAPI.reducerPath]: cartAPI.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      categoryAPI.middleware,
      brandAPI.middleware,
      productAPI.middleware,
      orderAPI.middleware,
      userAPI.middleware,
      wishlistAPI.middleware,
      reviewAPI.middleware,
      couponAPI.middleware,
      //   cartAPI.middleware,
    ]),
});

export const persistor = persistStore(store);
