import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
  products: [],
  loading: false,
  error: "",
  product: {},
};

export const getFeaturedProducts = createAsyncThunk(
  "products/featured",
  async (featured, { rejectWithValue }) => {
    try {
      const { data } = await Client.get(`/product/${featured}`);
      console.log("response data from featured", data.data.data);
      return data.data.data;
    } catch (error) {
      console.error("error on login", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductdetails = createAsyncThunk(
  "product/details",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Client.get(`/product/one-product/${id}`);
      console.log("get signle product", data);
      return data.data.data;
    } catch (error) {
      console.error("error on product dtails api", error);
      return rejectWithValue(
        "Sorry unable to find the product details. Try again later!"
      );
    }
  }
);

export const searchProduct = createAsyncThunk(
  "product/search",
  async (query, { rejectWithValue }) => {
    try {
      const { data } = await Client.get(`/product/search?query=${query}`);
      console.log("data from search", data);
      return data.data.data;
    } catch (error) {
      console.log("error on search", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getProductsbyBrands = createAsyncThunk(
  "product/get/brands",
  async (query, { rejectWithValue }) => {
    try {
      const { data } = await Client.get(`/product/brand/${query}`);
      console.log("data from brands", data.data);
      return data.data.data;
    } catch (error) {
      console.log("error on product by brands", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getProductsbyCategory = createAsyncThunk(
  "product/get/category",
  async (query, { rejectWithValue }) => {
    try {
      console.log("query on category", query);
      const { data } = await Client.get(
        `/product/product-by-category/${query}`
      );
      console.log("data from categorties", data.data);
      return data.data.data;
    } catch (error) {
      console.log("error on product by category", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const filterProduct = createAsyncThunk(
  "product/filter",
  async (
    { category, ram, storage, relevance, sortByPrice },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await Client.get(
        `/product/filter?ram=${ram}&storage=${storage}&catagory=${category}&relevance=${relevance}&sortByPrice=${sortByPrice}`
      );
      console.log("data from filter product", data);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeaturedProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(getFeaturedProducts.pending, (state, action) => {
      state.loading = true;
      state.products = [];
    });
    builder.addCase(getFeaturedProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getProductsbyCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(getProductsbyCategory.pending, (state, action) => {
      state.loading = true;
      state.products = [];
    });
    builder.addCase(getProductsbyCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getProductsbyBrands.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(getProductsbyBrands.pending, (state, action) => {
      state.loading = true;
      state.products = [];
    });
    builder.addCase(getProductsbyBrands.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(searchProduct.pending, (state, action) => {
      state.loading = true;
      state.products = [];
    });
    builder.addCase(searchProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(filterProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(filterProduct.pending, (state, action) => {
      state.loading = true;
      state.products = [];
    });
    builder.addCase(filterProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getProductdetails.fulfilled, (state, action) => {
      state.loading = false;
      state.product = action.payload;
    });
    builder.addCase(getProductdetails.pending, (state, action) => {
      state.loading = true;
      state.product = {};
    });
    builder.addCase(getProductdetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function

export default productSlice.reducer;
