import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const productAPI = createApi({
  reducerPath: "product",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  // tagTypes: ["Product"],
  endpoints: (builder) => ({
    createProduct: builder.mutation({
      query: (product) => ({
        url: "product/create",
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["Product"],
    }),

    getProducts: builder.query({
      query: () => "/product/all-products",
      providesTags: ["Product"],
    }),

    getProduct: builder.query({
      query: (id) => ({
        url: `/product/one-product/${id}`,
      })
    }),

    updateProduct: builder.mutation({
      query: (product) => ({
        url: "product/edit",
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation
} = productAPI;
