import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const reviewAPI = createApi({
  reducerPath: "review",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // getWishlist: builder.query({
    //   query: () => "/wishlist/get-list",
    //   providesTags: ["Wishlist"],
    //   transformResponse: (response) => {
    //     return response.data.data;
    //   },
    // }),

    createReview: builder.mutation({
      query: (data) => ({
        url: "/review/create-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UserOrders", "Invoices"],
    }),
  }),
});

export const { useCreateReviewMutation } = reviewAPI;
