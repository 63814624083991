import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const wishlistAPI = createApi({
  reducerPath: "wishlist",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWishlist: builder.query({
      query: () => "/wishlist/get-list",
      keepUnusedDataFor: 0,
      providesTags: ["Wishlist"],
      transformResponse: (response) => {
        return response.data.data;
      },
    }),

    addWishlist: builder.mutation({
      query: (productId) => ({
        url: "/wishlist/new-wish",
        method: "POST",
        body: { productId },
      }),
      invalidatesTags: ["Wishlist"],
    }),

    removeWishlist: builder.mutation({
      query: (productId) => ({
        url: `/wishlist/delete/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Wishlist"],
    }),
  }),
});

export const {
  useGetWishlistQuery,
  useAddWishlistMutation,
  useRemoveWishlistMutation,
} = wishlistAPI;
