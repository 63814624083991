export const formatPrice = (price) => {
  const formater = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
  });

  return formater.format(price);
};

export const getURL = () => process.env.REACT_APP_BACKEND_URL;
