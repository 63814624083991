import React, { forwardRef } from "react";
import { formatPrice } from "../../utils";
import logo from "../../assets/images/Logo.png";
import { getCountryByCode, getStateByCode } from "../../utils/static";

const Print = forwardRef((props, ref) => {
  const { order, billing, shipping, payment,discount } = props;
  let subTotal = 0;

  return (
    <div className="printC w-full mt-10" ref={ref}>
      <div className="flex justify-between">
        <div className="flex flex-col gap-3 ml-8">
          <img src={logo} className="h-28" alt="logo" />
          <span className="font-semibold text-3xl">Ecommerce</span>
        </div>

        <span className="font-semibold text-2xl mr-8">INVOICE</span>
      </div>

      <div className="flex justify-around mt-10">
        <div className="flex flex-col">
          <span className="font-semibold">Bill To</span>
          <span>{`${billing.landmark},${billing.houseNo} ${billing.streetName}`}</span>
          <span>{`${getStateByCode(billing.state, billing.country).label},${
            billing.city
          }-${billing.pincode}`}</span>
          <span>{getCountryByCode(billing.country).label}</span>
        </div>

        <div className="flex flex-col">
          <span className="font-semibold">Ship To</span>
          <span>{`${shipping.landmark},${shipping.houseNo} ${shipping.streetName}`}</span>
          <span>{`${getStateByCode(shipping.state, shipping.country).label},${
            shipping.city
          }-${shipping.pincode}`}</span>
          <span>{getCountryByCode(shipping.country).label}</span>
        </div>

        <div className="flex flex-col gap-3">
          <div>
            <span className="font-semibold">Invoice#</span>
            <span className="ml-2">{payment.razorpayPaymentId}</span>
          </div>

          <div>
            <span className="font-semibold">Invoice Date</span>
            <span className="ml-2">
              {new Date(payment.createdAt).toDateString()}
            </span>
          </div>

          <div>
            <span className="font-semibold">Payment Mode</span>
            <span className="ml-2">{payment.paymentMode}</span>
          </div>
        </div>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-8 ml-8 mr-10">
        <thead className="text-xs text-gray uppercase bg-[#d1d5db]">
          <tr>
            <th className="px-6 py-3">QTY</th>
            <th className="px-6 py-3">DESCRIPTION</th>
            <th className="px-6 py-3">UNIT PRICE</th>
            <th className="px-6 py-3">AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {order.productOrdered.map((item) => {
            subTotal += item.totalProductPrice;
            return (
              <tr className="border-b" key={item.id}>
                <td className="px-6 py-3">{item.quantity}</td>
                <td className="px-6 py-3">{item.name}</td>
                <td className="px-6 py-3">{formatPrice(item.price)}</td>
                <td className="px-6 py-3">
                  {formatPrice(item.totalProductPrice)}
                </td>
              </tr>
            );
          })}
          <tr className="">
            <td colSpan={2}></td>
            <td className="px-6 py-3 font-semibold">Subtotal</td>
            <td className="px-6 py-3">{formatPrice(subTotal)}</td>
          </tr>
          <tr className="">
            <td colSpan={2}></td>
            <td className="px-6 py-3 font-semibold">Dicount</td>
            <td className="px-6 py-3">{formatPrice(discount)}</td>
          </tr>
          <tr className="">
            <td colSpan={2}></td>
            <td className="px-6 py-3 font-bold">Total</td>
            <td className="px-6 py-3 font-bold text-xs text-black  bg-[#d1d5db]">
              {formatPrice(order.total)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default Print;
