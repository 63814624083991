import React, { useEffect, useState } from "react";
import Customaccordion from "../Components/Customaccordion";
import SearchFilter from "../Components/SearchFilter";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../redux/reducers/category.reducer";
import {
  filterProduct,
  getFeaturedProducts,
  getProductsbyBrands,
  getProductsbyCategory,
  searchProduct,
} from "../redux/reducers/product.reducer";
import { useLocation } from "react-router-dom";
import FeaturedCard from "../Components/FeaturedCard";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Pagination,
} from "@mui/material";
import Loader from "../Components/util/Loader";

const Ram = [
  {
    name: "2",
    _id: "2",
  },
  {
    name: "4",
    _id: "4",
  },
  {
    name: "8",
    _id: "8",
  },
  {
    name: "16",
    _id: "16",
  },
];

const Rom = [
  {
    name: "4",
    _id: "4",
  },
  {
    name: "8",
    _id: "8",
  },
  {
    name: "16",
    _id: "16",
  },
  {
    name: "32",
    _id: "32",
  },
  {
    name: "64",
    _id: "64",
  },
  {
    name: "128",
    _id: "128",
  },
  {
    name: "256",
    _id: "256",
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SearchResult() {
  const searchquery = useQuery();
  const query = searchquery.get("query");
  const brand = searchquery.get("brand");
  const cat = searchquery.get("category");
  const [category, setCategory] = useState(cat ? cat : "");
  const [relevance, setRelevence] = useState(false);
  const [pricesort, setPriceSort] = useState("asc");
  const [sort, setSort] = useState("default");
  const [ram, setRam] = useState("");
  const [rom, setRom] = useState("");
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categoryReducer);
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const { products, loading } = useSelector((state) => state.productReducer);

  // const [multipleCat, setMultipleCat] = useState([]);

  const [multipleRam, setMultipleRam] = useState([]);
  const [multipleS, setMultipleS] = useState([]);

  //for getting all the products according to query

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    console.log("search products", products);
  }, [products]);

  console.log(products);

  // useEffect(() => {
  //   console.log("category ram rom", category, ram, rom);
  //   if (category || ram || rom || sort !== "default") {
  //     if (sort === "pricehigh" || sort === "pricelow") {
  //       setRelevence(false);
  //       dispatch(
  //         filterProduct({
  //           category,
  //           ram,
  //           storage: rom,
  //           relevance: "",
  //           sortByPrice: sort === "pricehigh" ? "asc" : "desc",
  //         })
  //       );
  //     } else if (sort === "relevance") {
  //       dispatch(
  //         filterProduct({
  //           category,
  //           ram,
  //           storage: rom,
  //           relevance: relevance,
  //           sortByPrice: "",
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         filterProduct({
  //           category,
  //           ram,
  //           storage: rom,
  //           relevance: "",
  //           sortByPrice: "",
  //         })
  //       );
  //     }
  //   }
  // }, [category, ram, rom, dispatch, sort, relevance, pricesort]);

  function help(input) {
    const new1 = [...input];
    return new1.length > 0 ? `${new1.toString()}` : "";
  }

  useEffect(() => {
    const storageR = help(multipleS);
    const ramR = help(multipleRam);
    if (
      category ||
      multipleRam.length > 0 ||
      multipleS.length > 0 ||
      sort !== "default"
    ) {
      if (sort === "pricehigh" || sort === "pricelow") {
        setRelevence(false);
        console.log({ sort });
        dispatch(
          filterProduct({
            category,
            ram: ramR,
            storage: storageR,
            relevance: "",
            sortByPrice: sort === "pricehigh" ? "asc" : "desc",
          })
        );
      } else if (sort === "relevance") {
        dispatch(
          filterProduct({
            category,
            ram: ramR,
            storage: storageR,
            relevance: relevance,
            sortByPrice: "",
          })
        );
      } else {
        dispatch(
          filterProduct({
            category,
            ram: ramR,
            storage: storageR,
            relevance: "",
            sortByPrice: "",
          })
        );
      }
    } else {
      dispatch(getFeaturedProducts("all-products"));
    }
  }, [category, multipleRam, multipleS, dispatch, sort, relevance]);

  useEffect(() => {
    if (query) {
      dispatch(searchProduct(query));
    } else if (brand) {
      const dcode = decodeURIComponent(brand);

      dispatch(getProductsbyBrands(dcode));
    } else if (cat) {
      // console.log("category cat", cat);
      const dcode = decodeURIComponent(cat);
      // console.log("dcode", dcode);
      dispatch(getProductsbyCategory(dcode));
    } else {
      dispatch(getFeaturedProducts("all-products"));
    }
  }, [query, dispatch, brand, cat]);

  const handleClear = () => {
    setCategory("");
    setRelevence(false);
    setSort("default");
    setMultipleRam([]);
    setMultipleS([]);
    dispatch(getFeaturedProducts("all-products"));
  };

  const handleCheckboxChange = (catId) => {
    if (category === catId) {
      setCategory("");
    } else {
      setCategory(catId);
    }
  };

  return (
    <div className="grid-cols-6 gap-2 sm:px-8 md:px-12 lg:px-20 flex flex-col md:grid mb-12">
      <div>
        <div className="flex justify-between items-center mt-3 flex-wrap md:flex-nowrap p-3 md:p-0">
          <h6>Filters:</h6>
          <span className="cursor-pointer" onClick={handleClear}>
            Clear All
          </span>
        </div>
        <Customaccordion name="Category">
          <FormGroup>
            {categories?.map((cat) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(cat._id)}
                      checked={category === cat._id}
                    />
                  }
                  label={cat.name}
                />
              );
            })}
          </FormGroup>
        </Customaccordion>
        <Customaccordion name="RAM">
          <SearchFilter
            data={Ram}
            selected={multipleRam}
            handleChange={(e, value) => {
              const isChecked = e.target.checked;
              if (isChecked) {
                setMultipleRam([...multipleRam, value]);
              } else {
                setMultipleRam(multipleRam.filter((cat) => cat !== value));
              }
            }}
          />
        </Customaccordion>
        <Customaccordion name="STORAGE">
          <SearchFilter
            data={Rom}
            selected={multipleS}
            handleChange={(e, value) => {
              const isChecked = e.target.checked;
              if (isChecked) {
                setMultipleS([...multipleS, value]);
              } else {
                setMultipleS(multipleS.filter((cat) => cat !== value));
              }
            }}
          />
        </Customaccordion>
      </div>
      <div className="col-span-5">
        <div className="flex justify-end mt-3">
          <div>
            <span>Sort By:</span>
            <select
              className="ms-2"
              name="sort-by-menu"
              id="sort-by-menu"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="default">Default</option>
              <option value="pricehigh" onSelect={() => setPriceSort("asc")}>
                Price Low to High
              </option>
              <option value="pricelow" onSelect={() => setPriceSort("desc")}>
                Price High to Low
              </option>
              <option value="relevance" onSelect={() => setRelevence(true)}>
                Relevance
              </option>
            </select>
          </div>
        </div>
        <div className="grid min-[1844px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 grid-cols-1 mt-3 gap-3 px-3 justify-items-center">
          {loading ? (
            <Loader />
          ) : products.length === 0 ? (
            <div>No matches found</div>
          ) : (
            products.map((product) => {
              return (
                <FeaturedCard
                  is={isLoggedIn}
                  key={product._id}
                  data={product}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
