import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";

//stylesheets importing
import "./assets/styles/customUI.css";
import "./assets/styles/buttonStyles.css";
import "./assets/styles/inputStyle.css";
import "./assets/styles/layoutStyles.css";
import "./assets/styles/fontStyle.css";
//for including the tailwind.css
import "./assets/styles/tailwind.css";

import Header from "./Components/header";
import Footer from "./Components/Footer";

//pages
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ProductDetails from "./Pages/ProductDetails";
import FogotPassword from "./Pages/FogotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Cart from "./Pages/Cart";
import SearchResult from "./Pages/SearchResult";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Checkout from "./Pages/Checkout";
import UserProfile from "./Pages/Profile";
import ProfileLayout from "./Layouts/ProfileLayout";
import UserOrders from "./Pages/Orders";
import ChangePassword from "./Pages/ChangePassword";
import Wishlist from "./Pages/Wishlist";
import OrderDetails from "./Pages/OrderDetails";
import Success from "./Pages/Success";
import FAQ from "./Pages/FAQ";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ScrollTop from "./Components/util/ScrollTop";
import Privacy from "./Pages/Privacy";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Address from "./Pages/Address";
import PastReturns from "./Pages/PastReturns";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Lato,sans-serif",
    },
  },
  palette: {
    primary: {
      main: "#030027",
      dark: "#02001b",
      light: "#353352",
    },
    secondary: {
      main: "#C03221",
      dark: "#862317",
      light: "#cc5b4d",
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        color: "secondary",
      },
    },
  },
});

function ProtectedRoute() {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
}

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ScrollTop>
          <Toaster />
          <Header />
          <main style={{ minHeight: "55vh" }}>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/login" Component={Login} />
              <Route path="/signup" Component={Signup} />
              <Route path="/forgotpassword" Component={FogotPassword} />
              <Route path="/resetpassword/:token" Component={ResetPassword} />
              <Route path="/product/:id" Component={ProductDetails} />

              <Route path="/search" Component={SearchResult} />
              <Route path="/about" Component={About} />
              <Route path="/contactus" Component={ContactUs} />

              <Route path="/faq" Component={FAQ} />
              <Route path="/privacy" Component={Privacy} />
              <Route path="/terms" Component={Privacy} />
              <Route path="/services" Component={Privacy} />
              <Route path="/shipping" Component={Privacy} />

              <Route element={<ProtectedRoute />}>
                <Route path="/cart" Component={Cart} />
                <Route path="/checkout" Component={Checkout} />
                <Route path="/success" Component={Success} />
              </Route>

              <Route path="/profile" element={<ProfileLayout />}>
                <Route index Component={UserProfile} />
                <Route path="/profile/address" Component={Address} />
                <Route path="/profile/orders" Component={UserOrders} />
                <Route path="changepassword" Component={ChangePassword} />
                <Route path="/profile/wishlist" Component={Wishlist} />
                <Route path="/profile/orders/:id" Component={OrderDetails} />
                <Route path="/profile/returns" Component={PastReturns} />
              </Route>
            </Routes>
          </main>
          <Footer />
        </ScrollTop>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
