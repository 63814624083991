import React from "react";
import { useParams } from "react-router-dom";
import {
  useGenerateInvoiceQuery,
  useGetOrderDetailsQuery,
} from "../redux/apis/order";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Main from "../Components/order/Main";
import Shipment from "../Components/order/Shipment";
import Invoices from "../Components/order/Invoices";
import { capital } from "../Components/table/utils";
import Return from "../Components/order/Return";
import Loader from "../Components/util/Loader";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGenerateInvoiceQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <Loader />;
  }

  const userInfo = data.data.userInfo;
  const order = data.data.orderInfo;
  const payment = data.data.paymentInfo;
  const address = {
    billingAddress: { ...data.data.billingAddress },
    shippingAddress: { ...data.data.shippingAddress },
  };
  return (
    <>
      <div className="bg-gray-200 text-gray p-2">
        <h4 className="text-center">Order {`#${order._id}`}</h4>
        <div className="flex justify-around mt-5 flex-col md:flex-row gap-5 md:gap-0">
          <div className="flex flex-col">
            <span>Order Information</span>
            <span>
              Order Date :{" "}
              {moment(order.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
            <span>
              Order Status :{" "}
              <span className="text-sec">{capital(order.status)}</span>
            </span>
            <span>
              Payment Status :{" "}
              <span className="text-sec">{capital(payment.paymentStatus)}</span>
            </span>
          </div>

          <div className="flex flex-col">
            <span>Account Information</span>
            <span>Customer Name : {userInfo.name}</span>
            <span>Phone Number : {userInfo.phoneNo}</span>
            <span className="text-sec">Email : {userInfo.email}</span>
          </div>
        </div>
      </div>

      <div className="mt-4 text-gray">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Order" {...a11yProps(0)} />
          <Tab label="Shipments" {...a11yProps(1)} />
          {order.status === "delivered" && (
            <Tab label="Return/Exchange" {...a11yProps(2)} />
          )}
          {/* <Tab label="Invoices" {...a11yProps(2)} /> */}
        </Tabs>

        <CustomTabPanel value={value} index={0}>
          <Main order={order} address={address} payment={payment} />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Shipment />
        </CustomTabPanel>

        {order.status === "delivered" && (
          <CustomTabPanel value={value} index={2}>
            <Return
              products={order.productOrdered.map((p) => {
                return {
                  label: p.name,
                  value: p.id,
                };
              })}
              orderId={id}
            />
          </CustomTabPanel>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
