import React from "react";
import PastItems from "../Components/profile/PastItems";
import { useGetReturnsQuery } from "../redux/apis/order";
import { Typography } from "@mui/material";
import Loader from "../Components/util/Loader";

const PastReturns = () => {
  const { data, isLoading } = useGetReturnsQuery();

  console.log(data);

  return (
    <div className="min-h-[50vh]">
      <span className="text-lg font-semibold">View Past Returns/Exchanges</span>
      <div className="mt-[2rem]">
        {isLoading ? <Loader /> : <PastItems data={data.data} />}
      </div>
    </div>
  );
};

export default PastReturns;
