import React, { useMemo } from "react";
import { Formik } from "formik";
import { addressSchema } from "../../utils/schema";
import Select from "react-select";
import {
  getCities,
  getCountries,
  getCountryByCode,
  getStateByCode,
  getStates,
  getTypes,
} from "../../utils/static";
import {
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from "../../redux/apis/user";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddressForm = ({ address, error }) => {
  const [create, { isLoading: loadingC }] = useCreateAddressMutation();
  const [update, { isLoading: loadingU }] = useUpdateAddressMutation();
  const navigate = useNavigate();

  const isEdit = useMemo(() => (address ? true : false), [address]);

  const init = address
    ? {
        houseNo: address.houseNo,
        streetName: address.streetName,
        landmark: address.landmark,
        city: address.city,
        state: address.state,
        country: address.country,
        pincode: address.pincode,
        addressType: address.addressType,
      }
    : {
        houseNo: "",
        streetName: "",
        landmark: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        addressType: "Home",
      };

  const handleCreate = async (address) => {
    try {
      await create(address).unwrap();
      toast.success("Address created successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleUpdate = async (address) => {
    try {
      await update(address).unwrap();
      toast.success("Address updated successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSubmit = async (values) => {
    if (isEdit) {
      await handleUpdate(values);
    } else {
      await handleCreate(values);
    }
  };

  return (
    <Formik
      initialValues={init}
      validationSchema={addressSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        const countries = getCountries();

        const states = values.country ? getStates(values.country) : [];

        const cities = values.state
          ? getCities(values.state, values.country)
          : [];

        return (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row gap-0 md:gap-14">
              <div className="flex-[1] flex flex-col gap-2">
                <div>
                  <label htmlFor="houseNo">House No</label>
                  <br />
                  <input
                    type="text"
                    name="houseNo"
                    className="inputC"
                    placeholder="please enter your house no"
                    value={values.houseNo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.houseNo && touched.houseNo && errors.houseNo}
                </p>

                <div>
                  <label htmlFor="landmark">Landmark</label>
                  <br />

                  <input
                    type="text"
                    name="landmark"
                    className="inputC"
                    placeholder="please enter your landmark"
                    value={values.landmark}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.landmark && touched.landmark && errors.landmark}
                </p>

                <div>
                  <label htmlFor="streetName">Street Name</label>
                  <br />

                  <input
                    type="text"
                    name="streetName"
                    className="inputC"
                    placeholder="please enter your streetName"
                    value={values.streetName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.streetName && touched.streetName && errors.streetName}
                </p>
              </div>

              <div className="flex-[1] flex flex-col gap-2">
                <div>
                  <label htmlFor="country">Country</label>
                  <br />

                  <Select
                    className="basic-single"
                    name="country"
                    defaultValue={getCountryByCode(values.country)}
                    options={countries}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      setFieldValue("country", val.value);
                    }}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.country && touched.country && errors.country}
                </p>

                {values.country && (
                  <>
                    <div>
                      <label htmlFor="state">State</label>
                      <br />

                      <Select
                        className="basic-single"
                        name="state"
                        defaultValue={getStateByCode(
                          values.state,
                          values.country
                        )}
                        options={states}
                        onBlur={handleBlur}
                        onChange={(val) => setFieldValue("state", val.value)}
                      />
                    </div>
                    <p className="text-rose-500 text-sm">
                      {errors.state && touched.state && errors.state}
                    </p>
                  </>
                )}

                {values.state && (
                  <>
                    <div>
                      <label htmlFor="city">City</label>
                      <br />

                      <Select
                        className="basic-single"
                        name="city"
                        defaultValue={{
                          label: values.city,
                          value: values.city,
                        }}
                        options={cities}
                        onBlur={handleBlur}
                        onChange={(val) => setFieldValue("city", val.value)}
                      />
                    </div>
                    <p className="text-rose-500 text-sm">
                      {errors.city && touched.city && errors.city}
                    </p>
                  </>
                )}
              </div>

              <div className="flex-[1] flex flex-col gap-2">
                <div>
                  <label htmlFor="pincode">Pincode</label>
                  <br />
                  <input
                    type="text"
                    name="pincode"
                    className="inputC"
                    placeholder="please enter your pincode"
                    value={values.pincode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.pincode && touched.pincode && errors.pincode}
                </p>

                <div>
                  <label htmlFor="addressType">Type of Address</label>
                  <br />

                  <Select
                    className="basic-single"
                    name="addressType"
                    defaultValue={{
                      label: values.addressType,
                      value: values.addressType,
                    }}
                    options={getTypes()}
                    onBlur={handleBlur}
                    onChange={(val) => {
                      setFieldValue("addressType", val.value);
                    }}
                  />
                </div>
                <p className="text-rose-500 text-sm">
                  {errors.addressType &&
                    touched.addressType &&
                    errors.addressType}
                </p>
              </div>
            </div>

            <button
              disabled={loadingC || loadingU}
              type="submit"
              className="btn md:w-[25%] bg-primary mt-3 "
            >
              Update
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
