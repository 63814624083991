import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import toast from "react-hot-toast";
import { useCreateReviewMutation } from "../../redux/apis/review";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AddReview = ({ open, handleClose, obj }) => {
  const id = obj ? obj.id : null;
  const review = obj ? obj.review : null;
  const [create, { isLoading }] = useCreateReviewMutation();

  const navigate = useNavigate();

  const t = review ? review.text : "";
  const r = review ? review.rating : 0;

  const [rating, setRating] = useState(r);

  const [text, setText] = useState(t);

  useEffect(() => {
    const t = obj && obj.review ? obj.review.text : "";
    const r = obj && obj.review ? obj.review.rating : 0;
    setText(t);
    setRating(r);
  }, [obj]);

  const handleSubmit = async () => {
    if (!text) {
      toast.error("Please enter a review.");
      return;
    }

    if (rating === 0) {
      toast.error("Please give a rating.");
      return;
    }

    try {
      await create({
        productId: id,
        rating,
        text,
        createFlag: review ? false : true,
      }).unwrap();
      toast.success("Your review has been updated");
      handleClose();
      navigate("/profile/orders");
    } catch (e) {
      toast.error(e.data.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="p-[4rem] md:p-0">
          <span className="font-semibold text-lg block text-center">
            Add Review
          </span>
          <div className="mt-3">
            <textarea
              type="text"
              onChange={(e) => setText(e.target.value)}
              placeholder="Write Review"
              className="inputC"
              value={text}
              rows={4}
            ></textarea>

            <div className="flex gap-3 mt-4">
              <label>Give your rating</label>
              <Rating
                name="half-rating"
                precision={0.25}
                value={rating}
                onChange={(_, val) => setRating(val)}
              />
            </div>
          </div>

          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="btn mt-4"
          >
            Submit
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddReview;
