import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const orderAPI = createApi({
  reducerPath: "order",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdminOrders: builder.query({
      query: () => ({
        url: "/admin/orders-manage",
        method: "GET",
      }),
      providesTags: ["Orders"],
    }),

    updateOrder: builder.mutation({
      query: (data) => ({
        url: "/admin/update-order",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Orders", "UserOrders"],
    }),

    initiateOrder: builder.mutation({
      query: (data) => ({
        url: "/order/create-razorpay-orders",
        method: "POST",
        body: data,
      }),
    }),

    verifyPayment: builder.mutation({
      query: (data) => ({
        url: "/order/validate-razorpay-orders",
        method: "POST",
        body: data,
      }),
    }),

    getDashboardData: builder.query({
      query: () => `/admin/dashboard`,
    }),

    getUserOrders: builder.query({
      query: (status) => ({
        url: `/order/get-filter?status=${status}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["UserOrders"],
    }),

    getOrderDetails: builder.query({
      query: (orderId) => ({
        url: `/order/order-details/${orderId}`,
      }),
    }),
    generateInvoice: builder.query({
      query: (orderId) => ({
        url: `/order/generateInvoice?orderId=${orderId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Invoices"],
    }),
    submitOrder: builder.mutation({
      query: (orderdata) => ({
        url: "/order/new-order",
        method: "POST",
        body: orderdata,
      }),
      invalidatesTags: ["UserOrders", "Invoices"],
    }),

    reorderItems: builder.mutation({
      query: (orderId) => ({
        url: `/checkout/reorder-checkout/${orderId}`,
        method: "GET",
      }),
    }),

    getReturns: builder.query({
      query: () => ({
        url: "/order/userReturns",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Returns"],
    }),

    returnExchange: builder.mutation({
      query: (data) => ({
        url: "/order/returnExchangeProduct",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Returns"],
    }),
  }),
});

export const {
  useGetAdminOrdersQuery,
  useUpdateOrderMutation,
  useGetDashboardDataQuery,
  useGetUserOrdersQuery,
  useGetOrderDetailsQuery,
  useSubmitOrderMutation,
  useInitiateOrderMutation,
  useVerifyPaymentMutation,
  useGenerateInvoiceQuery,
  useReorderItemsMutation,
  useReturnExchangeMutation,
  useGetReturnsQuery,
} = orderAPI;
