import React from "react";
import WishlistCard from "../Components/WishlistCard";
import { useGetWishlistQuery } from "../redux/apis/wishlist";
import { Container } from "@mui/material";
import Loader from "../Components/util/Loader";

const Wishlist = () => {
  const { data, isLoading, isError } = useGetWishlistQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <Container>
      {data.length ? (
        <section
          id="Projects"
          className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-3 mb-5"
        >
          {data.map((product) => (
            <WishlistCard product={product.productInfo} />
          ))}
        </section>
      ) : (
        <div className="w-fit min-h-[40vh]">No Wishlisted products</div>
      )}
    </Container>
  );
};

export default Wishlist;
