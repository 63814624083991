import React from "react";
import { Formik } from "formik";
import { object, string } from "yup";

const ProfileForm = ({ user }) => {
  const validationSchema = object({
    email: string()
      .required("Email is Required")
      .email("Invalid email address"),
    name: string().required("No username provided"),
    phoneno: string().required("Phone is required!"),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <Formik
      initialValues={{
        email: user.email,
        name: user.name,
        phoneno: user.phoneno || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Username</label>
            <br />
            <input
              type="text"
              name="name"
              className="inputC md:w-[25%]"
              placeholder="please enter your username"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <p className="text-rose-500 text-sm">
            {errors.name && touched.name && errors.name}
          </p>

          <div>
            <label htmlFor="name">Email</label>
            <br />

            <input
              type="text"
              name="email"
              className="inputC md:w-[25%]"
              placeholder="please enter your email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <p className="text-rose-500 text-sm">
            {errors.email && touched.email && errors.email}
          </p>

          <div>
            <label htmlFor="name">Phone no</label>
            <br />

            <input
              type="text"
              name="phoneno"
              className="inputC md:w-[25%]"
              placeholder="please enter your phone"
              value={values.phoneno}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <p className="text-rose-500 text-sm">
            {errors.phoneno && touched.phoneno && errors.phoneno}
          </p>

          {/* <Button type="submit" sx={{ width: "15%" }} variant="contained">
            Update
          </Button> */}
        </form>
      )}
    </Formik>
  );
};

export default ProfileForm;
