import { useEffect, useState } from "react";
import BgImg from "../assets/images/page-header-bg.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartCount,
  getCartItems,
  reduceCartcount,
  resetSuccess,
  updateCart,
} from "../redux/reducers/cart.reducer";
import { cartTotalCalculator, totalCalculator } from "../utils/calculator.util";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { formatPrice, getURL } from "../utils";
import { useGetUserQuery } from "../redux/apis/user";
import Loader from "../Components/util/Loader";

export default function Cart() {
  const dispatch = useDispatch();
  const { data: resUser, isLoading: loadingU } = useGetUserQuery();
  const { cartData, success, cartcount } = useSelector(
    (state) => state.cartReducer
  );
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    if (e.target.name === "quantity") {
      setData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        temp.productOrdered[index].quantity = e.nativeEvent.data;
        return temp;
      });
    } else if (
      e.target.name === "decrease" &&
      data.productOrdered[index].quantity > 0
    ) {
      setData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        temp.productOrdered[index].quantity -= 1;
        return temp;
      });
    } else if (e.target.name === "increase") {
      setData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        temp.productOrdered[index].quantity += 1;
        return temp;
      });
    }
  };

  useEffect(() => {
    if (success) {
      toast.success("Your cart has been updated");
      setTimeout(() => {
        dispatch(resetSuccess());
      }, [2000]);
    }
  }, [success, dispatch]);

  const removeItem = (index) => {
    const result = window.confirm("Are you sure about removing this item?");
    if (result) {
      setData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        temp.productOrdered = temp.productOrdered.filter((_, i) => index !== i);
        console.log("temp on edit", temp);
        dispatch(
          updateCart({
            productOrdered: temp.productOrdered,
            total: cartTotalCalculator(data.productOrdered),
          })
        );
        dispatch(reduceCartcount(prev.productOrdered[index].quantity));
        return temp;
      });
    }
  };

  const handleUpdate = () => {
    const temp = {
      productOrdered: data.productOrdered,
      total: cartTotalCalculator(data.productOrdered),
    };
    dispatch(updateCart(temp));
    dispatch(getCartCount());
  };

  const handleProceed = () => {
    const isAdd = Boolean(resUser.data.address);
    if (!isAdd) {
      navigate("/profile/address?checkout=true", {
        replace: true,
      });
    } else {
      navigate("/checkout", {
        state: resUser.data,
      });
    }
  };

  useEffect(() => {
    console.log("cart items on page", cartData);
    if (cartData && cartData.productOrdered) {
      setData(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  if (loadingU) {
    return <Loader />;
  }

  return (
    <div>
      <div
        className="flex justify-center items-center w-full h-56"
        style={{
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
        }}
      >
        <h1 className="text-2xl">Shopping Cart</h1>
      </div>
      <div className="min-[1640px]:px-96 2xl:px-80 min-[1492px]:px-64 xl:px-56 min-[1200px]:px-44 lg:px-28  min-[849px]:px-40 md:px-32 min-[649px]:px-24 sm:px-20 px-8 mt-4 mb-5">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="col-span-2">
            <table
              className="text-left w-full"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead />
              <tbody>
                {data && data.productOrdered
                  ? data.productOrdered.map((item, index) => {
                      return (
                        <TableRow
                          data={item}
                          index={index}
                          handleChange={handleChange}
                          removeItem={removeItem}
                        />
                      );
                    })
                  : "No Item to display"}
              </tbody>
            </table>
          </div>
          <div>
            <div className="border-2 border-dotted bg-[#f9f9f9] border-[#ccc] p-3 min-w-52">
              <h3 className="text-xl border-b pb-2">Cart Total</h3>
              <div className="flex justify-between mt-3">
                <h6>Subtotal</h6>
                <h6>
                  {formatPrice(cartTotalCalculator(data?.productOrdered))}
                </h6>
              </div>
              <div className="flex justify-between mt-3">
                <h6>Total</h6>
                <h6>
                  {formatPrice(cartTotalCalculator(data?.productOrdered))}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-3">
          <Button
            disabled={cartcount === 0}
            variant="contained"
            onClick={handleUpdate}
          >
            Update Cart
          </Button>
          <Button
            disabled={cartcount === 0}
            variant="outlined"
            sx={{ marginLeft: "1rem" }}
            onClick={handleProceed}
          >
            Proceed to Checkout
          </Button>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

function TableRow({ data, index, handleChange, removeItem }) {
  console.log(data);
  return (
    <tr className="border-b">
      <Link to={`/product/${data.id}`}>
        <td className="flex items-center py-4 max-[550px]:flex-wrap">
          <img
            src={`${getURL()}${data?.image}`}
            style={{ width: 40, height: 40 }}
            alt=""
          />
          <span className="text-base ms-2">{data?.name}</span>
        </td>
      </Link>
      <td className="text-base ps-2 py-4 ">{formatPrice(data?.price)}</td>
      <td className="ps-2 py-4 ">
        <div className="flex items-center border p-3 ms-3 w-20">
          <input
            type="button"
            value="-"
            disabled={data.quantity === 1}
            name="decrease"
            className="border-0 cursor-pointer"
            data-field="quantity"
            onClick={(e) => handleChange(e, index)}
          />
          <input
            disabled
            name="quantity"
            value={data?.quantity}
            onChange={(e) => handleChange(e, index)}
            className="border-0 w-full text-center"
          />
          <input
            type="button"
            value="+"
            className="border-0 cursor-pointer"
            name="increase"
            onClick={(e) => handleChange(e, index)}
            data-field="quantity"
          />
        </div>
      </td>
      <td className="text-base ps-2 py-4 ">
        {formatPrice(totalCalculator(data?.quantity, data?.price))}
      </td>
      <td className="py-4 ps-3">
        <button
          className="close"
          type="button"
          onClick={() => removeItem(index)}
        >
          &#x2715;
        </button>
      </td>
    </tr>
  );
}

function TableHead() {
  return (
    <thead>
      <tr className="border-b">
        <th className="text-lg text-[#999]">Product</th>
        <th className="text-lg text-[#999] ps-2">Price</th>
        <th className="text-lg text-[#999] ps-4">Quantity</th>
        <th className="text-lg text-[#999] ps-2">Total</th>
        <th></th>
      </tr>
    </thead>
  );
}
