import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import Rating from "./Rating";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PreviewIcon from "@mui/icons-material/Preview";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import {
  useAddWishlistMutation,
  useRemoveWishlistMutation,
} from "../redux/apis/wishlist";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { formatPrice, getURL } from "../utils";
import { addtoCart } from "../redux/reducers/cart.reducer";

export default function FeaturedCard({ data, is }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wished, setWished] = useState(false);

  const handlePreview = (id) => {
    navigate(`/product/${id}`);
  };
  const [addWishlist, { isSuccess }] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();
  useEffect(() => {
    if (isSuccess) {
      toast.success("Added to wishlist.");
    }
  }, [isSuccess]);

  const addtowishlist = (id) => {
    if (wished) {
      setWished(false);
      removeWishlist(id);
    } else {
      setWished(true);
      addWishlist(id);
    }
  };

  useEffect(() => {
    if (data) {
      setWished(data?.wish);
    }
  }, [data]);

  const add = () => {
    if (is) {
      const temp = {
        id: data._id,
        image: data.image[0],
        name: data.name,
        price: data.CurrentPrice,
        quantity: 1,
      };
      dispatch(addtoCart(temp));
      toast.success("Your items are added to your cart");
    } else {
      toast.error("Please login to add to cart");
    }
  };

  return (
    <>
      <Card className="featuredcard">
        <div
          className="cardmedia"
          style={{
            backgroundImage: `url(${getURL()}${data.image[0]})`,
          }}
        >
          <button
            className="addtowishlistbtn"
            onClick={() => addtowishlist(data?._id)}
          >
            {wished ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </button>
          <div className="cardmediafooter">
            <button className="nostylebtn" onClick={add}>
              <AddShoppingCartIcon />
            </button>
            <button className="nostylebtn">|</button>
            <button
              className="nostylebtn"
              onClick={() => handlePreview(data._id)}
            >
              <PreviewIcon />
            </button>
          </div>
        </div>
        <CardContent
          sx={{ cursor: "pointer" }}
          onClick={() => handlePreview(data._id)}
        >
          <div className="product-categories">
            <p>{data?.catagory_name}</p>
          </div>
          <h5 className="featuredProductname">{data.name}</h5>
          <div className="product-price">
            <span className="new-price text-darkblue font-bold">
              {formatPrice(data.CurrentPrice)}
            </span>
            <span className="old-price">
              <strike>{formatPrice(data.MRP)}</strike>
            </span>
          </div>
          <Rating
            ratingvalue={data.rating ? data.rating : 0}
            readonly={true}
            reviews={data.noOfReviews}
          />
        </CardContent>
      </Card>
    </>
  );
}
