import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { capital } from "../table/utils";
import moment from "moment";
import { BASE } from "../../utils/data";
import { Link } from "react-router-dom";

const ReturnRow = ({ row }) => {
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <Link
          to={`/product/${row.orderId.productOrdered.id}`}
          className="flex gap-3 items-center"
        >
          <img
            src={`${BASE}/${row.orderId.productOrdered.image}`}
            alt={row.orderId.productOrdered.name}
            className="h-12 w-12"
          />
          <span>{row.orderId.productOrdered.name}</span>
        </Link>
      </TableCell>
      <TableCell>{capital(row.type)}</TableCell>
      <TableCell>{capital(row.status)}</TableCell>
      <TableCell>
        {moment(new Date(row.requestedAt)).format("MMMM Do YYYY HH::mm")}
      </TableCell>
    </TableRow>
  );
};

export default ReturnRow;
