import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccess, userSignup } from "../redux/reducers/user.reducer";
import { Formik } from "formik";
import { object, string } from "yup";
import Alert from "../Components/util/Alertmessage";
import { getCartCount, getCartItems } from "../redux/reducers/cart.reducer";
import toast from "react-hot-toast";

export default function Signup() {
  //yup validation schema..
  const validationSchema = object({
    email: string()
      .required("Email is Required")
      .email("Invalid email address"),
    password: string()
      .required("No password provided")
      .min(8, "Password is too short - should be 8 characters minimum"),
    name: string().required("No username provided"),
    phoneno: string()
      .required("Phone is required!")
      .length(10, "Phone number should contain 10 numbers!"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, signUpError } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (success) {
      dispatch(getCartCount());
      dispatch(getCartItems());

      navigate("/");
      dispatch(resetSuccess());
    }
  }, [success, navigate, dispatch]);

  const handleSubmit = (values) => {
    dispatch(userSignup(values));
    if (signUpError) {
      toast.error(signUpError);
    }
  };

  return (
    <div className="login overflow-auto min-h-screen">
      <div className="loginBox md:w-[25%]">
        <h3>Sign Up</h3>
        {/* {signUpError.length ? <Alert msg={signUpError} severity="error" /> : ""} */}
        <Formik
          initialValues={{
            email: "",
            name: "",
            password: "",
            phoneno: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-3">
                <label htmlFor="name">Username</label>
                <input
                  type="text"
                  name="name"
                  className="inputC mt-2"
                  placeholder="Please enter your username"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.name && touched.name && errors.name}
              </p>
              <div className="mt-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="inputC mt-2"
                  placeholder="Please enter your email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.email && touched.email && errors.email}
              </p>
              <div className="mt-3">
                <label htmlFor="phoneno">Phone</label>
                <input
                  type="text"
                  name="phoneno"
                  className="inputC mt-2"
                  placeholder="Please enter your Phone number"
                  value={values.phoneno}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.phoneno && touched.phoneno && errors.phoneno}
              </p>
              <div className="mt-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  className="inputC mt-2"
                  placeholder="Please enter your Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.password && touched.password && errors.password}
              </p>
              <div className="flex flex-col">
                <Link className="link" to="/login">
                  Already have an account?
                </Link>
                <button className="btn mt-5">SIGNUP</button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
