import React, { useRef } from "react";
import Items from "./Items";
import { useReactToPrint } from "react-to-print";
import Html2Pdf from "html2pdf.js";
import Print from "./Print";
import { getCountryByCode, getStateByCode } from "../../utils/static";

const Main = ({ order, address, payment }) => {
  const billing = address.billingAddress;
  const shipping = address.shippingAddress;

  const ref = useRef();

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => ref.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        console.log(html);
        const exporter = new Html2Pdf(html, {
          filename: `invoice_${payment.razorpayPaymentId}.pdf`,
        });
        await exporter.getPdf(true);
      }
    },
  });

  return (
    <>
      <div className="flex mt-5 flex-col md:flex-row gap-5 md:gap-0">
        <div className="flex flex-col gap-2 md:ml-[7rem]">
          <span className="font-medium text-lg">Billing Address</span>
          <span>{`${billing.landmark},${billing.houseNo} ${billing.streetName}`}</span>
          <span>{`${getStateByCode(billing.state, billing.country).label},${
            billing.city
          }-${billing.pincode}`}</span>
          <span>{getCountryByCode(billing.country).label}</span>
        </div>

        <div className="flex flex-col gap-2 md:ml-[25rem]">
          <span className="font-medium text-lg">Shipping Address</span>
          <span>{`${shipping.landmark},${shipping.houseNo} ${shipping.streetName}`}</span>
          <span>{`${getStateByCode(shipping.state, shipping.country).label},${
            shipping.city
          }-${shipping.pincode}`}</span>
          <span>{getCountryByCode(shipping.country).label}</span>
        </div>
      </div>

      <div className="mt-8">
        <span>Ordered Items</span>
        <Items
          discount={order.discount}
          status={order.status}
          rows={order.productOrdered}
          total={Number(order.total)}
        />
      </div>

      {payment.paymentStatus === "Completed" && (
        <div className="mt-5">
          <button onClick={handleDownload} className="btn md:w-[25%]">
            Download Invoice
          </button>
          <div style={{ display: "none" }}>
            <Print
              discount={order.discount}
              ref={ref}
              order={order}
              billing={billing}
              shipping={shipping}
              payment={payment}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
