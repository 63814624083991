import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";
import api from "../../utils/axiosInstance.util";

export const categoryAPI = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => "/category/get-all",
      providesTags:['Categories'],
      transformResponse: (response) => {
        return response.data.data.map((val) => ({
          id: val._id,
          name: val.name,
        }));
      },
    }),

    createCategory: builder.mutation({
      query: (category) => ({
        url: "/category/create",
        method: "POST",
        body: category,
      }),
      invalidatesTags : ['Categories']
    }),
  }),
});

export const { useGetCategoriesQuery, useCreateCategoryMutation } = categoryAPI;
