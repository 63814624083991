import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const cartAPI = createApi({
  reducerPath: "cart1",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    addCart: builder.mutation({
      query: (data) => ({
        url: "/checkout/add-one-item",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      invalidatesTags: ["Cart"],
    }),

    getItems: builder.query({
      query: () => "/checkout/get",
      transformResponse: (response) => {
        return response.data.checkout;
      },
      providesTags: ["Cart"],
    }),

    updateCart: builder.mutation({
      query: (data) => ({
        url: "/checkout/edit",
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      invalidatesTags: ["Cart"],
    }),

    getCount: builder.query({
      query: () => "/checkout/count",

      providesTags: ["Cart"],
    }),
  }),
});

export const {
  useAddCartMutation,
  useGetItemsQuery,
  useGetCountQuery,
  useUpdateCartMutation,
} = cartAPI;
