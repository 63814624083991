import React, { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../redux/reducers/offer.reducer";
import { getURL } from "../utils";
import Loader from "./util/Loader";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "70vh",
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "right 25% bottom 45%",
    backgroundRepeat: "no-repeat",
  },
  content: {
    position: "absolute",
    top: "40%",
    left: "10%",
    color: "white",
    zIndex: 100,
  },
  imageContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
}));

const MyCarousel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { offers, loading } = useSelector((state) => state.offerReducer); //getting offers from reducer

  useEffect(() => {
    dispatch(getOffers());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      <Carousel
        // className="customiseCarousel"
        className="w-full"
        navButtonsAlwaysVisible
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosNewIcon />}
        indicatorContainerProps={{
          style: {
            left: "-37%",
            top: "80%",
            position: "absolute",
            zIndex: 10,
          },
        }}
      >
        {offers?.map((offer) => (
          <div className={classes.imageContainer} key={offer._id}>
            <div className={classes.overlay}></div>
            <Paper
              elevation={0}
              className={classes.image}
              style={{
                backgroundImage: `url(${getURL()}${offer.image})`,
                height: 450,
              }}
            >
              <div className={classes.content}>
                <Typography color={"#030027"} fontSize={"80px"} variant="h5">
                  {offer.offerName}
                </Typography>
                <Typography
                  fontSize={"20px"}
                  color={"#68667D"}
                  marginTop={1}
                  variant="body1"
                >
                  {offer.Description}
                </Typography>
              </div>
            </Paper>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MyCarousel;
