import { object, string, mixed, number } from "yup";

// brand schema

export const brandSchema = object({
  category: string().required(),
  name: string().required(),
  image: mixed().required(),
});

// category schema

export const categorySchema = object({
  name: string().required(),
  image: mixed().required(),
});

export const addressSchema = object({
  houseNo: string().required("House number is required"),
  streetName: string().required(),
  landmark: string().required(),
  city: string().required(),
  state: string().required(),
  country: string().required(),
  pincode: string().required(),
  addressType: mixed().required().oneOf(["Home", "Office", "Other"]),
});
