import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";

import Alert from "../Components/util/Alertmessage";
import { resetSuccess, userLogin } from "../redux/reducers/user.reducer";
import { getCartCount, getCartItems } from "../redux/reducers/cart.reducer";
import toast from "react-hot-toast";

export default function Login() {
  // const [showPassword, seShow] = useState(false);

  const validationSchema = object({
    email: string().email("Invalid email address").required("Required"),
    password: string()
      .required("No password provided")
      .min(8, "Password is too short - should be 8 characters minimum"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (success) {
      dispatch(getCartCount());
      dispatch(getCartItems());
      dispatch(resetSuccess());
      navigate("/");
    }
  }, [success, navigate, dispatch]);

  const handleSubmit = (values) => {
    dispatch(userLogin(values));
    if (error) {
      toast.error(error);
    }
  };

  return (
    <div className="login min-h-screen">
      <div className="loginBox md:w-[25%]">
        <h3>Sign In</h3>
        {/* {error.length ? <Alert msg={error} severity="error" /> : ""} */}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="inputC mt-2"
                  placeholder="please enter your email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.email && touched.email && errors.email}
              </p>
              <div className="mt-3">
                <label htmlFor="email">Password</label>
                <input
                  type="password"
                  name="password"
                  className="inputC mt-2"
                  placeholder="please enter your Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </div>
              <p className="text-rose-500 text-sm">
                {errors.password && touched.password && errors.password}
              </p>
              <div className="flex flex-col">
                <Link className="link" to="/forgotpassword">
                  Forgot Password?
                </Link>
                <button
                  type="submit"
                  className="btn mt-4 bg-primary"
                  disabled={isSubmitting}
                >
                  Login
                </button>
                <Link to="/signup" className="link">
                  Don't have an account?
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
