import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Print from "./Print";

const i = [
  {
    id: 1,
    invoiceNo: 12121,
    date: new Date().toDateString(),
    amount: 12121,
  },
];

const Row = ({ row }) => {
  const r = useRef();

  const handlePrint = useReactToPrint({
    content: () => r.current,
  });

  return (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.invoiceNo}
      </TableCell>
      <TableCell>{row.date}</TableCell>
      <TableCell>{row.amount}</TableCell>
      <TableCell>
        <button
          onClick={handlePrint}
          className="bg-primary text-white p-2 rounded-md"
        >
          Print
        </button>
      </TableCell>
      <div style={{ display: "none" }}>
        <Print ref={r} />
      </div>
    </TableRow>
  );
};

const Invoices = () => {
  return (
    <>
      <span className="font-medium text-lg">Invoices</span>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i.map((row) => (
              <Row row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Invoices;
