import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
    categories: [],
    loading: false,
    error: "",
    success: false,
};

export const getCategories = createAsyncThunk(
    "Category/get",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await Client.get("/category/get-all");
            console.log("response from categry", data);
            return data.data.data;
        } catch (error) {
            console.error("error on category", error);
            return rejectWithValue("Invalid credentials. Please try again!");
        }
    }
);

const categorySlice = createSlice({
    name: "Category",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.loading = false;
            state.error = "";
        });
        builder.addCase(getCategories.pending, (state, action) => {
            state.loading = true;
            state.error = "";
        });
        builder.addCase(getCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});


export default categorySlice.reducer;
