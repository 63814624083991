import React from "react";
import { Grid } from "@mui/material";

import img1 from "../assets/images/hero/image 6.png";
import img2 from "../assets/images/hero/image 7.png";
import img3 from "../assets/images/hero/image 11.png";
import img4 from "../assets/images/hero/image 10.png";

function Collage() {
  return (
    <>
      <Grid container spacing={2} className="collageContainer">
        <Grid item lg={5} sm={4} xs={12}>
          <div className="collageCard mx-1 mx-sm-0">
            <img src={img4} alt="loading.." className="bg-contain" />
            <div className="collageCardcontent">
              <a href="#">TRENDING NOW</a>
              <h6>The Focus and Accent On Your Spring</h6>
              <a href="#">Wardrobe Discover Now</a>
            </div>
          </div>
        </Grid>
        <Grid item lg={7} sm={8} xs={12}>
          <div className="h-50 ms-1 mb-1">
            <div className="collageCard">
              <img src={img1} alt="" srcset="" />

              <div className="collageCardcontent">
                <a href="#">TRENDING NOW</a>
                <h6>The Focus and Accent On Your Spring</h6>
                <a href="#">Wardrobe Discover Now</a>
              </div>
            </div>
          </div>
          <Grid
            container
            spacing={2}
            className="h-50"
            sx={{ width: "100%", marginLeft: "4px" }}
          >
            <Grid item xs={12} sm={6}>
              <div className="collageCard">
                <img src={img2} alt="" srcset="" />

                <div className="smallcardcontent">
                  <a href="#">TRENDING NOW</a>
                  <h6>The Focus and Accent On Your Spring</h6>
                  <a href="#">Wardrobe Discover Now</a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="collageCard">
                <img src={img3} alt="" srcset="" />

                <div className="smallcardcontent">
                  <a href="#">TRENDING NOW</a>
                  <h6>The Focus and Accent On Your Spring</h6>
                  <a href="#">Wardrobe Discover Now</a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Collage;
