import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";
import toast from "react-hot-toast";
import { userAPI } from "../apis/user";

const initialState = {
  user: {},
  isLoggedIn: false,
  loading: false,
  error: "",
  resetError: "",
  forgotError: "",
  signUpError: "",
  success: false,
  forgotSuccess: false,
};

export const userLogin = createAsyncThunk(
  "user/login",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/user/login", payload);
      console.log("response from login", data);
      localStorage.setItem("token", JSON.stringify(data.data.accessToken));
      toast.success("Login Successful");
      return data.data;
    } catch (error) {
      console.error("error on login", error);
      return rejectWithValue("Invalid credentials. Please try again!");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotpassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/user/forgot-password", payload);
      console.log("response from forgot", data);
      return data.data;
    } catch (error) {
      console.error("error on forgot", error);
      return rejectWithValue(
        "Sorry we couldn't process the request. Please try again!"
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetpassword",
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const { data } = await Client.post(
        "/user/reset-password",
        { newPassword: password },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response from forgot", data);
      return data.data;
    } catch (error) {
      console.error("error on reset", error);
      return rejectWithValue("Sorry unable to change the password!");
    }
  }
);

export const userSignup = createAsyncThunk(
  "user/signup",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/user/register", payload);
      console.log("response from signup", data);
      localStorage.setItem("token", JSON.stringify(data.data.accessToken));
      return data.data;
    } catch (error) {
      console.error("error on signup", error);
      return rejectWithValue(
        "Sorry can't create account. Please try again later!"
      );
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetSuccess: (state) => {
      state.success = false;
    },
    logoutSuccess: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.loading = false;
      state.error = "";
      state.success = false;
      state.forgotSuccess = false;
      localStorage.removeItem("token");
      state.signUpError = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.loading = false;
      state.success = true;
      state.error = "";
    });
    builder.addCase(userLogin.pending, (state, action) => {
      state.loading = true;
      state.isLoggedIn = false;
      state.user = {};
      state.error = "";
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(userSignup.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.loading = false;
      state.success = true;
      state.signUpError = "";
    });
    builder.addCase(userSignup.pending, (state, action) => {
      state.loading = true;
      state.isLoggedIn = false;
      state.user = {};
      state.signUpError = "";
    });
    builder.addCase(userSignup.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.loading = false;
      state.signUpError = action.payload;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotSuccess = true;
      state.loading = false;
      state.forgotError = "";
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.loading = true;
      state.forgotSuccess = false;
      state.forgotError = "";
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotSuccess = false;
      state.loading = false;
      state.forgotError = action.payload;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.success = true;
      state.loading = false;
      state.resetError = "";
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
      state.success = false;
      state.resetError = "";
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.resetError = action.payload;
    });

    builder.addCase("auth/logout", (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.loading = false;
      state.signUpError = "";
      state.resetError = "";
      state.forgotError = "";
      state.error = "";
      state.success = false;
      state.forgotSuccess = false;
      localStorage.removeItem("token");
    });
  },
});

export const logout = () => (dispatch) => {
  dispatch(logoutSuccess());

  dispatch(userAPI.util.resetApiState());
};

// Action creators are generated for each case reducer function
export const { resetSuccess, logoutSuccess } = userSlice.actions;

export default userSlice.reducer;
