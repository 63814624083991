import React from "react";
import { useLocation } from "react-router-dom";

const Success = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <div className="mt-10 flex items-center justify-center flex-col gap-4">
      <span className="text-3xl font-bold text-blue-500">Thank You!</span>

      <div className="flex flex-col gap-1">
        <span className="text-center">
          Your order number is <span>{params.get("order_id")}</span>
        </span>
        <span className="text-center md:text-start">
          It will be delivered in 2-12 business days.
        </span>
      </div>

      <div>
        <p className="text-center md:text-start">
          Email us at{" "}
          <span className="text-blue-500 cursor-pointer">
            bluestreamtechteam@gmail.com
          </span>{" "}
          with any questions, suggestions.
        </p>
      </div>
    </div>
  );
};

export default Success;
