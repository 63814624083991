import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatPrice } from "../../utils";
import AddReview from "../modals/AddReview";

const Items = ({ rows, total, status, discount }) => {
  const [open, setOpen] = React.useState(false);
  const [obj, setObj] = useState();
  const handleOpen = (review, id) => {
    setObj({
      review: review,
      id: id,
    });
    setOpen(true);
  };

  console.log(obj);
  const handleClose = () => setOpen(false);

  const isAction = status === "delivered";

  let quantity = 0;
  return (
    <>
      <AddReview open={open} handleClose={handleClose} obj={obj} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Total</TableCell>

              {isAction && <TableCell align="center">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              quantity += row.quantity;
              const pId = row.id;
              return (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{formatPrice(row.price)}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">
                    {formatPrice(row.totalProductPrice)}
                  </TableCell>
                  {isAction && (
                    <TableCell align="right">
                      {row.review ? (
                        <>
                          {row.review.verified ? (
                            <button
                              onClick={() => handleOpen(row.review, row.id)}
                              className="btn"
                            >
                              Edit Review
                            </button>
                          ) : (
                            <span className="w-full h-full block text-center text-darkblue">
                              Verification in progress.
                            </span>
                          )}
                        </>
                      ) : (
                        <button
                          onClick={() => handleOpen(row.review, row.id)}
                          className="btn"
                        >
                          Add Review
                        </button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="center">
                Discount
              </TableCell>
              <TableCell align="center">{formatPrice(discount)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }} colSpan={2}>
                Total Quantity
              </TableCell>
              <TableCell align="center">{quantity}</TableCell>
              <TableCell align="center">{formatPrice(total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Items;
