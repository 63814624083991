import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";

// export default function SearchFilter({ data, selected, handleChange }) {
//   return (
//     <>
//       <RadioGroup
//         row
//         aria-labelledby="demo-row-radio-buttons-group-label"
//         name="registrationType"
//         onChange={handleChange}
//       >
//         {data?.map((cat) => {
//           return (
//             <React.Fragment key={cat._id}>
//               <FormControlLabel
//                 value={cat._id}
//                 control={<Radio />}
//                 label={cat.name}
//                 checked={selected === cat._id}
//               />
//             </React.Fragment>
//           );
//         })}
//       </RadioGroup>
//     </>
//   );
// }

export default function SearchFilter({ data, selected, handleChange }) {
  return (
    <FormGroup>
      {data?.map((cat) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleChange(e, cat._id)}
                defaultChecked={selected.includes(cat._id)}
              />
            }
            label={cat.name}
          />
        );
      })}
    </FormGroup>
  );
}
