import React from "react";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/Logo.png";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

const style = {
  color: "#C03221",
  width: 30,
  height: 30,
};

const links = [
  {
    Icon: <FacebookIcon sx={style} />,
    target:
      "https://www.facebook.com/Bluestreamio-105817491202379/?ref=aymt_homepage_panel&eid=ARABC_ZT2uqdFutyiwG2OowLj2k2SzP5DugCNDLOq8I3pLNv2Pd1DfM9Qzs4NHCHpJl6U8QDmWj9sjc1",
  },
  {
    Icon: <InstagramIcon sx={style} />,
    target: "https://www.instagram.com/bluestream.io/?igshid=13stdpoqn85f5",
  },
  {
    Icon: <LinkedInIcon sx={style} />,
    target:
      "https://www.linkedin.com/company/bluestreamsoftware/?viewAsMember=true",
  },
];

export default function Footer() {
  const { isLoggedIn } = useSelector((state) => state.userReducer);

  return (
    <>
      <footer className="bg-darkblue text-white flex flex-col md:flex-row py-[4rem] px-[3rem] gap-5">
        <div className="md:flex-[2]">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
          <p className="text-sm md:text-[18px]">
            {" "}
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
        </div>

        <div className="md:flex-[1]">
          <h6 className="font-semibold text-[20px]">Useful Links</h6>

          <Link to="about" className="text-[14px] mt-3">
            About
          </Link>

          <Link to={"/services"} className="block text-[14px] mt-1">
            Our Services
          </Link>

          <Link to="/faq" className="block text-[14px] mt-1">
            FAQ
          </Link>

          <Link to="contactus" className="block text-[14px] mt-1">
            Contact Us
          </Link>
        </div>

        <div className="md:flex-[1]">
          <h6 className="font-semibold text-[20px]">Customer Service</h6>

          <Link to={"/privacy"} className="block text-[14px] mt-1">
            Privacy
          </Link>
          <Link to={"/shipping"} className="block text-[14px] mt-1">
            Shipping
          </Link>
          <Link to={"/terms"} className="block text-[14px] mt-1">
            Terms and condition
          </Link>
        </div>

        <div className="md:flex-[1]">
          <h6 className="font-semibold text-[20px]">My Account</h6>

          <div className="">
            {isLoggedIn ? (
              <>
                <Link to={"/profile"} className="block text-[14px] mt-1">
                  My Account
                </Link>

                <Link
                  to={"/profile/wishlist"}
                  className="block text-[14px] mt-1"
                >
                  My Wishlist
                </Link>
                <Link to={"/cart"} className="block text-[14px] mt-1">
                  View Cart
                </Link>
              </>
            ) : (
              <Link to="/login" className="block text-[14px] mt-1">
                Sign in
              </Link>
            )}
          </div>
        </div>

        <div className="md:flex-[1]">
          <p className="font-semibold text-[14px] mb-3">
            Got Question? call us 24/7
          </p>
          <div className="flex items-center gap-3">
            <LocalPhoneIcon color="secondary" />{" "}
            <h5 className=""> +0123456789</h5>
          </div>
          <div className="mt-[3rem]">
            <h6 className="font-semibold text-[16px] mb-3">Follow Us on</h6>
            <div className="flex items-center gap-4">
              {links.map((link) => (
                <a href={link.target} target="_blank" rel="noreferrer">
                  <div className="flex items-center">{link.Icon}</div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
      <p className="text-[14px] text-[#6F6F6F] text-center border-t-2 bg-darkblue py-[2rem] px-[2rem]">
        <a href="https://bluestream.io/">
          Copyright © Bluestream.io. All Rights Reserved.
        </a>
      </p>
    </>
  );
}
