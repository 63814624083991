import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useCheckuser() {
    const { user } = useSelector((state) => state.userReducer);
    const token = localStorage.getItem("token");

    const exist = useMemo(() => {
        if (user && token) {
            console.log("user token", user, token);
            return true;
        } else {
            return false;
        }
    }, [user, token]);

    return { exist };
}
