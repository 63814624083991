export const totalCalculator = (quantity, price) => {
    if (price && price.includes(",")) {
        price = price.replace(/\,/g, "");
    }
    return Number(quantity) * Number(price);
};

export const cartTotalCalculator = (cartItems) => {
    let totals = [];
    if (cartItems) {
        cartItems.forEach((item) => {
            const total = totalCalculator(item.quantity, item.price);
            totals.push(total);
        });
        return totals.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
    } else {
        return 0;
    }
};
