import DashboardIcon from "@mui/icons-material/Dashboard";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LockIcon from "@mui/icons-material/Lock";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HomeIcon from "@mui/icons-material/Home";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import { getURL } from ".";

export const userLinks = [
  {
    title: "Profile",
    path: "/profile",
    Icon: <DashboardIcon />,
  },
  {
    title: "Address",
    path: "/profile/address",
    Icon: <HomeIcon />,
  },
  {
    title: "orders",
    path: "/profile/orders",
    Icon: <Inventory2Icon />,
  },
  {
    title: "Change Password",
    path: "/profile/changepassword",
    Icon: <LockIcon />,
  },
  {
    title: "Wishlist",
    path: "/profile/wishlist",
    Icon: <FavoriteBorderIcon />,
  },
  {
    title: "View Past Returns/Exchange",
    path: "/profile/returns",
    Icon: <PublishedWithChangesOutlinedIcon />,
  },
];

export const orderStatus = [
  {
    label: "pending",
    value: "pending",
  },
  {
    label: "processing",
    value: "processing",
  },
  {
    label: "shipped",
    value: "shipped",
  },
  {
    label: "delivered",
    value: "delivered",
  },
  {
    label: "cancelled",
    value: "cancelled",
  },
];

export const BASE = getURL();
