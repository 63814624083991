import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ProductCarousel from "./CardCarousel";
import FeaturedCard from "./FeaturedCard";
import {
  getFeaturedProducts,
  setCotroller,
} from "../redux/reducers/product.reducer";
import Loader from "./util/Loader";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const featuretype = {
  0: "featured",
  1: "discounted",
  2: "all-products",
};

export default function HighlightProducts() {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const { products, loading } = useSelector((state) => state.productReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //useEffect for finding the featured products according to the tab active
  useEffect(() => {
    dispatch(getFeaturedProducts(featuretype[value]));
  }, [dispatch, value]);

  return (
    <Box sx={{ width: "100%" }} className="feauredsection">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Featured" {...a11yProps(0)} />
          <Tab label="Discounted" {...a11yProps(1)} />
          <Tab label="All" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CustomTabPanel value={value} index={0}>
            <ProductCarousel
              datas={products}
              Component={FeaturedCard}
              defaultslides={4}
              responsive={[
                {
                  breakpoint: 1834,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 1037,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ProductCarousel
              datas={products}
              Component={FeaturedCard}
              defaultslides={4}
              responsive={[
                {
                  breakpoint: 1834,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ProductCarousel
              datas={products}
              Component={FeaturedCard}
              defaultslides={3}
              responsive={[
                {
                  breakpoint: 1834,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 1037,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            />
          </CustomTabPanel>
        </>
      )}
    </Box>
  );
}
