import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { useReturnExchangeMutation } from "../../redux/apis/order";
import { useNavigate } from "react-router-dom";

const options = [
  {
    label: "Return",
    value: "return",
  },
  {
    label: "Exchange",
    value: "exchange",
  },
];

const Return = ({ products, orderId }) => {
  const [selectedP, setSelectedP] = useState("");

  const [option, setOption] = useState(options[0]);
  const [returns, { isLoading }] = useReturnExchangeMutation();
  const reasonRef = useRef();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reason = reasonRef.current.value;
    if (!reason) {
      toast.error("Please enter a reason.");
      return;
    }

    if (!selectedP) {
      return toast.error("Please select a product.");
    }

    try {
      await returns({
        orderId,
        productId: selectedP,
        reason,
        type: option.value,
      });
      toast.success("Your request has been submitted.");
      navigate("/profile/orders");
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Select a Product *</label>
        <Select
          options={products}
          className="mt-2"
          onChange={(v) => setSelectedP(v.value)}
        />
      </div>

      <div className="mt-4">
        <label>What do you want to do?</label>
        <Select
          options={options}
          className="mt-2"
          defaultValue={options[0]}
          onChange={(v) => setOption(v)}
        />
      </div>

      <div className="mt-4">
        <label>Could you tell us the reason? *</label>
        <textarea ref={reasonRef} className="inputC mt-2" rows={4}></textarea>
      </div>

      <button disabled={isLoading} className="btn md:w-[25%] mt-5">
        Submit
      </button>
    </form>
  );
};

export default Return;
