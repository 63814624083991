import React, { useEffect } from "react";

import MyCarousel from "../Components/Carousel";
import Collage from "../Components/Collage";
import HighlightProducts from "../Components/HighlightProducts";
import BrandsCarousel from "../Components/BrandsCarousel";
// import Banner from "../Components/admin/modals/Banner";

// Creating a default theme

export default function Home() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   handleOpen();
  //   return () => {
  //     handleClose();
  //   };
  // }, []);

  return (
    <>
      {/* <Banner handleClose={handleClose} open={open} /> */}
      <MyCarousel />
      <Collage />
      <BrandsCarousel />
      <HighlightProducts />
    </>
  );
}
