import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ProfileForm from "../Components/profile/ProfileForm";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../redux/apis/user";
import Loader from "../Components/util/Loader";

const UserProfile = () => {
  const { data, isLoading, isError } = useGetUserQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Profile</Typography>
      </Stack>
      <ProfileForm user={data.data.user} />
    </Container>
  );
};

export default UserProfile;
