import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import React from "react";
import Scrollbar from "../scrollbar/Scrollbar";
import Head from "../table/Head";

import TableEmptyRows from "../table/EmptyRow";
import { emptyRows } from "../table/utils";
import TableNoData from "../table/NoData";
import { useTable } from "../../hooks/useTable";
import ReturnRow from "./ReturnRow";

const PastItems = ({ data }) => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
    handleSelectAllClick,
    handleSort,
    notFound,
    selected,
    filterName,
    page,
    rowsPerPage,
    order,
    orderBy,
    dataFiltered,
  } = useTable(data);

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <Head
              order={order}
              orderBy={orderBy}
              rowCount={data.length}
              onRequestSort={handleSort}
              headLabel={[
                { id: "name", label: "Product Name" },
                { id: "type", label: "Type" },
                { id: "status", label: "Status" },
                { id: "date", label: "Request Date" },
              ]}
            />
            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <ReturnRow
                    row={row}
                    selected={selected}
                    handleClick={handleClick}
                  />
                ))}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, data.length)}
              />

              {notFound && <TableNoData query={filterName} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default PastItems;
