import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
  cartData: {},
  loading: false,
  error: "",
  success: false,
  cartcount: 0,
};

export const addtoCart = createAsyncThunk(
  "cart/add",
  async (product, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/checkout/add-one-item", product);
      console.log("data from add to cart", data);
      return data.data;
    } catch (error) {
      console.log("error on add to cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCartItems = createAsyncThunk(
  "cart/get",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Client.get("/checkout/get");
      console.log("cart items", data);
      return data.data.checkout;
    } catch (error) {
      console.log("error on get cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCart = createAsyncThunk(
  "cart/udpate",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client.post("/checkout/edit", payload);
      console.log("response from edit cart", data);
      return data.data;
    } catch (error) {
      console.log("error on get cart", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCartCount = createAsyncThunk(
  "cart/get/count",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Client.get("/checkout/count");
      console.log("response from cart count", data);
      return data.data.checkoutCount;
    } catch (error) {
      console.log("error on get cart count", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    resetSuccess: (state) => {
      state.success = false;
    },
    reduceCartcount: (state, action) => {
      state.cartcount -= action.payload;
    },

    emptyCart: (state, action) => {
      state.cartData = {};
      state.cartcount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addtoCart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
      state.cartcount += 1;
    });
    builder.addCase(addtoCart.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(addtoCart.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(updateCart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateCart.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(updateCart.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      state.loading = false;
      state.cartData = action.payload;
    });
    builder.addCase(getCartItems.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getCartItems.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getCartCount.fulfilled, (state, action) => {
      state.loading = false;
      state.cartcount = action.payload;
    });
    builder.addCase(getCartCount.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(getCartCount.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    });
  },
});

export const { resetSuccess, reduceCartcount, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;
