import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Customaccordion({ name, children }) {
    
    return (
        <>
            <Accordion
            sx={{ boxShadow: "none", borderBottom: "1px solid #BBC3CE" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <h4>{name}</h4>
            </AccordionSummary>
            <AccordionDetails>
            {children}
            </AccordionDetails>
        </Accordion>
        </>
    );
}
