import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { getProductdetails } from "../redux/reducers/product.reducer";
import { addtoCart, resetSuccess } from "../redux/reducers/cart.reducer";
import toast, { Toaster } from "react-hot-toast";
import {
  useAddWishlistMutation,
  useRemoveWishlistMutation,
} from "../redux/apis/wishlist";
import useCheckuser from "../hooks/useCheckuser";
import { formatPrice, getURL } from "../utils";
import Loader from "../Components/util/Loader";
import { capital } from "../Components/table/utils";
import { GetColorName } from "hex-color-to-color-name";

export default function ProductDetails() {
  const { id } = useParams();
  const imageRef = useRef();
  const [quantity, setQuantity] = useState(1);
  const [active, setActive] = useState(0);
  const [value, setValue] = React.useState(0);
  const [wished, setWished] = useState(false);
  const dispatch = useDispatch();

  const { product, loading } = useSelector((state) => state.productReducer);
  const { success } = useSelector((state) => state.cartReducer);
  const [addWishlist, { isSuccess }] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const { exist } = useCheckuser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (product) {
      setWished(product?.wish);
    }
  }, [product]);

  useEffect(() => {
    if (success) {
      toast.success("Your items are added to your cart");
      setTimeout(() => {
        dispatch(resetSuccess());
      }, [2000]);
    }
  }, [success, dispatch]);

  useEffect(() => {
    dispatch(getProductdetails(id));
  }, [id, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleQuantity = (action) => {
    if (action === "decrease" && quantity > 1) {
      setQuantity((prev) => prev - 1);
    } else if (action === "increase") {
      setQuantity((prev) => prev + 1);
    }
  };

  const handleAddtocart = () => {
    const temp = {
      id: product._id,
      image: product.image[0],
      name: product.name,
      price: product.CurrentPrice,
      quantity: quantity,
    };
    dispatch(addtoCart(temp));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added to wishlist");
    }
  }, [isSuccess]);

  const addtoWishlist = () => {
    if (wished) {
      setWished(false);
      removeWishlist(id);
    } else {
      setWished(true);
      addWishlist(id);
    }
  };

  if (loading) {
    return <Loader />;
  }

  console.log(product);

  return (
    <div className="flex justify-center">
      <div className="container">
        <div className="grid sm:grid-cols-2">
          <div>
            <div className="flex justify-center items-center h-80 w-full p-10">
              <img
                src={
                  product?.image?.length
                    ? `${getURL()}${product?.image[0]}`
                    : ""
                }
                className="w-full h-full"
                ref={imageRef}
                alt="loading"
              />
            </div>
            <div className="flex">
              {product?.image?.map((img, index) => {
                return (
                  <div
                    key={img}
                    className="w-32 h-40 flex justify-center items-center p-2"
                    style={{
                      border: active === index ? "1px solid #3e2ee8" : "none",
                    }}
                    onClick={() => {
                      setActive(index);
                      imageRef.current.src = `${getURL()}${img}`;
                    }}
                  >
                    <img
                      src={`${getURL()}${img}`}
                      className="w-full h-full"
                      alt="loading"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-5 sm:mt-0">
            <h2 className="text-2xl">{product?.name}</h2>
            <div className="flex items-center mt-2">
              <Rating
                name="half-rating-read"
                defaultValue={product.rating}
                precision={0.5}
                readOnly
              />
              <span className="text-[#999] text-xs ms-3">
                ( {product.noOfReviews} Reviews )
              </span>
            </div>
            <div className="flex">
              <h6 className="text-darkblue font-bold text-xl mt-2">
                {formatPrice(product?.CurrentPrice)}
              </h6>
              <h6 className="text-[#525158] text-lg mt-2 ms-3">
                <strike>{formatPrice(product?.MRP)}</strike>
              </h6>
            </div>
            <div className="flex items-center mt-3">
              <label>Qty:</label>
              <div className="flex items-center border p-3 ms-3 w-20">
                <input
                  type="button"
                  value="-"
                  disabled={quantity === 1}
                  className="border-0 cursor-pointer"
                  onClick={() => handleQuantity("decrease")}
                  data-field="quantity"
                />
                <input
                  type="number"
                  step="1"
                  max=""
                  disabled
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  name="quantity"
                  className="border-0 w-full text-center"
                />
                <input
                  type="button"
                  value="+"
                  onClick={() => handleQuantity("increase")}
                  className="border-0 cursor-pointer"
                  data-field="quantity"
                />
              </div>
            </div>
            <div className="flex mt-3 flex-wrap md:flex-nowrap">
              {exist ? (
                <>
                  <button className="btn" onClick={handleAddtocart}>
                    <AddShoppingCartIcon />
                    <span className="ms-2">ADD TO CART</span>
                  </button>
                  <button
                    className="btn bg-darkblue  border-0 flex items-center ms-0 mt-3 md:ms-7 md:mt-0"
                    onClick={addtoWishlist}
                  >
                    {wished ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    <span style={{fontSize:'12px'}} className="ms-3 text-white">
                      {wished ? "REMOVE FROM WISHLIST" : "ADD TO WISHLIST"}
                    </span>
                  </button>
                </>
              ) : (
                <Link className="shopNowbtn p-3" to="/login">
                  Login to use add to cart
                </Link>
              )}
            </div>
          </div>
        </div>
        <Tabs
          sx={{ marginTop: "1rem" }}
          value={value}
          onChange={handleChange}
          centered
          variant="scrollable"
        >
          <Tab label="Description" />
          <Tab label="Additional Information" />
          <Tab label="Shipping & Returns" />
          <Tab label="Reviews" />
        </Tabs>
        <div className="border-2 mb-6">
          {value === 0 ? (
            <Description data={product?.description} />
          ) : value === 1 ? (
            <AdditionalInfo variants={[]} />
          ) : value === 2 ? (
            <DeliveryReturns />
          ) : value === 3 ? (
            <Reviews reviews={product.reviews} />
          ) : (
            ""
          )}
        </div>
      </div>
      <Toaster />
    </div>
  );
}

function Description({ data }) {
  return (
    <div className="p-3">
      <h3 className="text-lg">Product Information</h3>
      <p className="mt-2 text-[#999] text-sm">{data}</p>
    </div>
  );
}

function AdditionalInfo({ variants }) {
  if (variants.length === 0)
    return <div className="p-3 text-[#999]">There are no variants.</div>;
  return (
    <div className="p-3">
      <h3 className="text-lg">Information</h3>

      {variants.map((v) => (
        <div className="flex gap-2 items-center mt-2">
          <h4 className="text-lg">{capital(v.variantType)}</h4>
          {v.variantType === "color" ? (
            <>
              <span
                className="w-5 h-5 rounded-full"
                style={{ backgroundColor: `${v.value}` }}
              ></span>
              {GetColorName(v.value)}
            </>
          ) : (
            <p className="text-[#999] text-sm">{v.value.toUpperCase()}</p>
          )}
        </div>
      ))}
    </div>
  );
}

function DeliveryReturns() {
  return (
    <div className="p-3">
      <h3 className="text-lg">Delivery & returns</h3>
      <p className="mt-2 text-[#999] text-sm">
        We deliver to over 100 countries around the world. For full details of
        the delivery options we offer, please view our Delivery informationWe
        hope you'll love every purchase, but if you ever need to return an item
        you can do so within a month of receipt. For full details of how to make
        a return, please view our Returns information
      </p>
    </div>
  );
}

function Reviews({ reviews }) {
  if (reviews.length === 0)
    return <div className="p-3 text-[#999]">There are no reviews yet.</div>;
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 p-3 max-h-[20rem] overflow-auto">
      {reviews.map((r) => (
        <>
          <div>
            <h3>{r.username}</h3>
            <Rating
              name="half-rating-read"
              defaultValue={r.rating}
              precision={0.5}
              readOnly
            />
          </div>
          <div className="col-span-2">
            <p className="mt-2 text-[#999] text-sm">{r.text}</p>
          </div>
        </>
      ))}
    </div>
  );
}
