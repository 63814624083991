import { Country, State, City } from "country-state-city";

export const getCountries = () => {
  const countries = Country.getAllCountries();
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.isoCode,
    };
  });
};

export const getStates = (country) => {
  const states = State.getStatesOfCountry(country);
  return states.map((state) => {
    return {
      value: state.isoCode,
      label: state.name,
    };
  });
};

export const getCities = (state, country) => {
  const cities = City.getCitiesOfState(country, state);
  return cities.map((city) => {
    return {
      value: city.name,
      label: city.name,
    };
  });
};

export const getCountryByCode = (code) => {
  const country = Country.getCountryByCode(code);
  if (!country) {
    return {};
  }
  return {
    label: country.name,
    value: country.isoCode,
  };
};

export const getStateByCode = (s, c) => {
  const state = State.getStateByCodeAndCountry(s, c);
  if (!state) {
    return {};
  }
  return {
    label: state.name,
    value: state.isoCode,
  };
};

export const getTypes = () => {
  const types = ["Home", "Office", "Other"];
  return types.map((type) => {
    return {
      value: type,
      label: type,
    };
  });
};
