import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const s = [
  {
    id: 1,
    shippingNo: 12121,
    date: new Date().toDateString(),
    trackingNo: "#12121",
  },
];

const Shipment = () => {
  return (
    <>
      <span className="font-medium text-lg">Shipments</span>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Shipping Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Tracking Number</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {s.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.shippingNo}
                </TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.trackingNo}</TableCell>
                <TableCell>
                  <button className="bg-primary text-white p-2 rounded-md">
                    View
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Shipment;
