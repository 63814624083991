import React from "react";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductCarousel = ({ datas, Component, defaultslides, responsive }) => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 300,
    arrows: true,
    slidesToShow: defaultslides,
    slidesToScroll: defaultslides,
    initialSlide: 0,
    responsive: responsive,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
  };

  return (
    <Slider className="slider" {...settings}>
      {datas?.map((data) => (
        <Grid sx={{ width: "100%" }} key={data._id} item>
          <Component data={data} is={isLoggedIn} />
        </Grid>
      ))}
    </Slider>
  );
};

export default ProductCarousel;
