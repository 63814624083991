import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from ".";

export const userAPI = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/admin/all-user",
        method: "GET",
      }),
    }),

    getUser: builder.query({
      providesTags: ["User"],
      query: () => ({
        url: "/user/get-user-details",
        cache: "no-cache",
      }),
    }),

    updateUser: builder.mutation({
      query: (data) => ({
        url: "",
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/user/change-password",
        body: data,
        method: "POST",
      }),
    }),

    getAddress: builder.query({
      query: () => `/user/getUserAddress`,
      providesTags: ["Address"],
    }),

    updateAddress: builder.mutation({
      query: (data) => ({
        url: "/user/editUserAddress",
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Address", "User"],
    }),

    createAddress: builder.mutation({
      query: (data) => ({
        url: "/user/createUserAddress",
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Address", "User"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useCreateAddressMutation,
  useGetAddressQuery,
  useUpdateAddressMutation,
} = userAPI;
