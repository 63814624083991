import React, { useEffect, useState } from "react";
import { useGetUserOrdersQuery } from "../redux/apis/order";
import { orderStatus } from "../utils/data";
import { Typography } from "@mui/material";
import OrdersList from "../Components/orders/list";
import Loader from "../Components/util/Loader";

const UserOrders = () => {
  const [currentStatus, setCurrentStatus] = useState("pending");
  const { data, isLoading, refetch, isError } =
    useGetUserOrdersQuery(currentStatus);

  useEffect(() => {
    refetch();
  }, [currentStatus, refetch]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  const orders = data.data.data;

  return (
    <div>
      <Typography variant="h5">View {currentStatus} Orders</Typography>
      <select
        onChange={(e) => setCurrentStatus(e.target.value)}
        className="mt-2 md:w-[25%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
      >
        {orderStatus.map((s) => (
          <option value={s.value}>{s.label}</option>
        ))}
      </select>

      <div className="mt-4">
        <OrdersList data={orders} isAdmin={false} />
      </div>
    </div>
  );
};

export default UserOrders;
