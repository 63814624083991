import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../utils";
import {
  emptyCart,
  getCartCount,
  getCartItems,
} from "../redux/reducers/cart.reducer";
import { cartTotalCalculator } from "../utils/calculator.util";
import { useForm } from "react-hook-form";
import {
  useInitiateOrderMutation,
  useSubmitOrderMutation,
  useVerifyPaymentMutation,
} from "../redux/apis/order";
import toast, { Toaster } from "react-hot-toast";
import { useGetUserQuery } from "../redux/apis/user";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Select from "react-select";
import { getCities, getCountries, getStates, getTypes } from "../utils/static";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useRedeemCouponMutation,
  useVerifyCouponMutation,
} from "../redux/apis/coupon";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [verifyCoupon, { isLoading: loadingCV }] = useVerifyCouponMutation();
  const [redeemCoupon, { isLoading: loadingCR }] = useRedeemCouponMutation();

  const [submitOrder, { isLoading: loadingS }] = useSubmitOrderMutation();

  const { cartData } = useSelector((state) => state.cartReducer);
  const [type, setType] = useState("COD");
  const [same, setSame] = useState(true);

  const [coupon, setCoupon] = useState("");
  const [applied, setApplied] = useState(false);
  const [discount, setDiscount] = useState({
    type: "",
    discount: 0,
  });

  const { discountAmt, total } = useMemo(() => {
    const current = cartTotalCalculator(cartData?.productOrdered);
    let discountAmt;

    if (discount.type === "percent") {
      discountAmt = (discount.discount / 100) * current;
    } else {
      discountAmt = discount.discount;
    }

    return {
      total: current - discountAmt,
      discountAmt,
    };
  }, [discount, cartData?.productOrdered]);

  const [initiate, { isLoading: loadingIn }] = useInitiateOrderMutation();

  const [verify, { isLoading: loadingV }] = useVerifyPaymentMutation();

  const { state } = useLocation();

  const user = useMemo(() => state.user, [state]);

  const address = useMemo(() => state.address, [state]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const selectedCountry = watch("country");
  const selectedState = watch("state");

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  const countries = useMemo(() => getCountries(), []);

  const states = useMemo(() => {
    return selectedCountry ? getStates(selectedCountry) : [];
  }, [selectedCountry]);

  const cities = useMemo(() => {
    return selectedState ? getCities(selectedState, selectedCountry) : [];
  }, [selectedState, selectedCountry]);

  function handleSuccess(orderId) {
    toast.success("Order has been placed successfully");
    dispatch(getCartCount());
    dispatch(emptyCart());
    navigate(`/success?order_id=${orderId}`);
  }

  async function handlePayment(data, billingAddress) {
    const amount = total * 100;

    try {
      const res = await initiate({
        amount: amount,
        currency: "INR",
        receipt: "test",
      }).unwrap();

      const options = {
        key: process.env.REACT_APP_RAZORPAY_ID,
        amount: res.data.amount,
        description: "Test Mode",
        order_id: res.data.id,

        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phoneno,
        },

        config: {
          display: {
            hide: [
              {
                method: "paylater",
              },
            ],
          },
        },

        handler: async (response) => {
          const r = await verify({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          }).unwrap();
          const q = await submitOrder({
            total: amount / 100,
            phoneNo: user.phoneno,
            billingAddress: { ...billingAddress },
            paymentMode: "razorpay",
            paymentStatus: "Completed",
            razorpayOrderId: response.razorpay_order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            couponCode: applied ? coupon : null,
            discount: discountAmt,
          }).unwrap();
          if (applied) {
            await redeemCoupon(coupon);
          }
          handleSuccess(q.data.newOrder._id);
        },
        theme: {
          color: "#C03221",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (err) {
      console.log(err);
      toast.error(err.data.message);
    }
  }

  const onSubmit = async (data) => {
    let billingAddress = null;
    if (same) {
      billingAddress = {
        houseNo: address.houseNo,
        streetName: address.streetName,
        landmark: address.landmark,
        city: address.city,
        state: address.state,
        country: address.country,
        pincode: address.pincode,
        addressType: address.addressType,
      };
    } else {
      billingAddress = { ...data };
    }

    if (type === "razorpay") {
      await handlePayment(data, billingAddress);
    } else {
      try {
        if (applied) {
          await redeemCoupon(coupon);
        }
        const q = await submitOrder({
          total,
          phoneNo: user.phoneno,
          paymentMode: "cod",
          paymentStatus: "Pending",
          billingAddress: { ...billingAddress },
          couponCode: applied ? coupon : null,
          discount: discountAmt,
        }).unwrap();
        handleSuccess(q.data.newOrder._id);
      } catch (err) {
        toast.error(err.data.message);
      }
    }
  };

  const handleCoupon = async () => {
    if (!coupon) return toast.error("Please enter a coupon code");

    try {
      const couponRes = await verifyCoupon(coupon).unwrap();
      setDiscount({
        discount: couponRes.data.discount,
        type: couponRes.data.discountType,
      });
      setApplied(true);
      toast.success("Coupon code applied successfully");
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);
      toast.error(err.data.message);
    }
  };

  const removeCoupon = () => {
    setCoupon("");
    setApplied(false);
    setDiscount({
      discount: 0,
      type: "",
    });
    toast.success("Coupon code is removed");
  };

  return (
    <section className="bg-white py-8 antialiase md:py-16">
      <form
        className="mx-auto max-w-screen-xl px-4 2xl:px-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
          <div className="min-w-0 flex-1 space-y-8">
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-gray">
                Delivery Details
              </h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="your_name"
                    className="mb-2 block text-sm font-medium text-gray "
                  >
                    {" "}
                    Your name{" "}
                  </label>
                  <input
                    type="text"
                    value={user.name}
                    id="name"
                    name="name"
                    className="inputC"
                    placeholder="Bonnie Green"
                  />
                </div>
                <div>
                  <label
                    htmlFor="your_email"
                    className="mb-2 block text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Your email*{" "}
                  </label>
                  <input
                    type="email"
                    value={user.email}
                    name="email"
                    id="your_email"
                    className="inputC"
                    placeholder="name@flowbite.com"
                  />
                </div>

                <div>
                  <label
                    htmlFor="phone-input-3"
                    className="mb-2 block text-sm font-medium text-gray-900"
                  >
                    {" "}
                    Phone Number*{" "}
                  </label>
                  <div className="flex items-center">
                    <div className="relative w-full">
                      <input
                        type="text"
                        value={user.phoneno}
                        id="phone-input"
                        className="inputC"
                        placeholder="123-456-7890"
                        name="phone"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray">
                Shipping Details
              </h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="mb-2 block text-sm font-medium text-gray ">
                    Address
                  </label>
                  <textarea
                    className="inputC"
                    value={`${address.houseNo},${address.landmark},${address.streetName},${address.city},${address.pincode}`}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex gap-5 items-center">
                <h3 className="text-xl font-semibold text-gray">
                  Billing Details
                </h3>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => setSame(e.target.checked)}
                        color="secondary"
                        defaultChecked={same}
                      />
                    }
                    label="Same as Shipping"
                  />
                </FormGroup>
              </div>
              {!same && (
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      House No
                    </label>

                    <input
                      type="text"
                      {...register("houseNo")}
                      name="houseNo"
                      id="houseNo"
                      className="inputC"
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      Landmark
                    </label>

                    <input
                      type="text"
                      {...register("landmark", {
                        required: !same,
                      })}
                      name="landmark"
                      id="landmark"
                      className="inputC"
                    />
                    {/* {errors.landmark && (
                      <p className="text-rose-500 text-sm">
                        {errors.landmark.message.toString()}
                      </p>
                    )} */}
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      Street Name
                    </label>

                    <input
                      type="text"
                      {...register("streetName", {
                        required: !same,
                      })}
                      name="streetName"
                      id="streetName"
                      className="inputC"
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      Country
                    </label>

                    <Select
                      className="basic-single"
                      name="country"
                      {...register("country", {
                        required: !same,
                      })}
                      options={countries}
                      onChange={(val) => {
                        setValue("country", val.value);
                      }}
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      State
                    </label>

                    <Select
                      className="basic-single"
                      name="state"
                      {...register("state", {
                        required: !same,
                      })}
                      options={states}
                      onChange={(val) => {
                        setValue("state", val.value);
                      }}
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      City
                    </label>

                    <Select
                      className="basic-single"
                      name="city"
                      {...register("city", {
                        required: !same,
                      })}
                      options={cities}
                      onChange={(val) => {
                        setValue("city", val.value);
                      }}
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      Pincode
                    </label>

                    <input
                      type="text"
                      {...register("pincode", {
                        required: !same,
                      })}
                      name="pincode"
                      id="pincode"
                      className="inputC"
                    />
                  </div>

                  <div>
                    <label className="mb-2 block text-sm font-medium text-gray ">
                      Address Type
                    </label>

                    <Select
                      className="basic-single"
                      name="addressType"
                      {...register("addressType", {
                        required: !same,
                      })}
                      defaultValue={{
                        label: "Home",
                        value: "Home",
                      }}
                      options={getTypes()}
                      onChange={(val) => {
                        setValue("addressType", val.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray">Payment</h3>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                <div className="rounded-lg border border-gray bg-gray-50 p-4 ps-4">
                  <div className="flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="razorpay"
                        type="radio"
                        name="paymentmethod"
                        value="razorpay"
                        defaultValue=""
                        onChange={(e) => setType(e.target.value)}
                        className="inputC accent-ter text-white bg-white border-white"
                      />
                    </div>
                    <div className="ms-4 text-sm">
                      <label
                        htmlFor="credit-card"
                        className="font-medium leading-none text-gray-900"
                      >
                        Razorpay
                      </label>
                    </div>
                  </div>
                </div>
                <div className="rounded-lg border border-gray bg-gray-50 p-4 ps-4">
                  <div className="flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="pay-on-delivery"
                        aria-describedby="pay-on-delivery-text"
                        type="radio"
                        name="paymentmethod"
                        value="COD"
                        defaultChecked
                        onChange={(e) => setType(e.target.value)}
                        className="inputC accent-ter text-white"
                      />
                    </div>
                    <div className="ms-4 text-sm">
                      <label
                        htmlFor="pay-on-delivery"
                        className="font-medium leading-none text-gray-900"
                      >
                        {" "}
                        Cash on delivery{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray">
                Apply Coupon here
              </h3>
              <div className="flex flex-col md:flex-row gap-3">
                <input
                  onChange={(e) => setCoupon(e.target.value)}
                  disabled={applied || loadingCV}
                  value={coupon}
                  name="coupon"
                  className="inputC md:w-[25%]"
                />
                {applied ? (
                  <button
                    type="button"
                    onClick={removeCoupon}
                    className="btn md:w-[25%] bg-primary"
                  >
                    <DeleteIcon />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleCoupon}
                    className="btn md:w-[25%] bg-primary"
                  >
                    APPLY
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
            <div className="flow-root">
              <div className="-my-3 divide-y divide-gray-200">
                <dl className="flex items-center justify-between gap-4 py-3">
                  <dt className="text-base font-normal text-darkblue">
                    Subtotal
                  </dt>
                  <dd className="text-base font-medium text-darkblue">
                    {formatPrice(cartTotalCalculator(cartData?.productOrdered))}
                  </dd>
                </dl>

                <dl className="flex items-center justify-between gap-4 py-3">
                  <dt className="text-base font-normal text-darkblue">
                    Discount
                  </dt>
                  <dd className="text-base font-medium text-darkblue">
                    {formatPrice(discountAmt)}
                  </dd>
                </dl>

                <dl className="flex items-center justify-between gap-4 py-3">
                  <dt className="text-base font-bold text-darkblue">Total</dt>
                  <dd className="text-base font-bold text-darkblue">
                    {formatPrice(total)}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="space-y-3">
              <button
                disabled={
                  loadingIn || loadingV || loadingS || loadingCV || loadingCR
                }
                type="submit"
                className="flex w-full items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white "
              >
                PLACE ORDER
              </button>
            </div>
          </div>
        </div>
      </form>
      <Toaster />
    </section>
  );
};

export default Checkout;
