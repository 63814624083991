import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Badge } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Search from "./Search";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../redux/reducers/brand.reducer";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../redux/reducers/user.reducer";
import toast from "react-hot-toast";
import AccountMenu from "./util/AccountMenu";
import { useGetCategoriesQuery } from "../redux/apis/category";
import { getCartCount } from "../redux/reducers/cart.reducer";
import useCheckuser from "../hooks/useCheckuser";

import logo from "../assets/images/Logo.png";
import { capital } from "./table/utils";
import { useGetCountQuery } from "../redux/apis/cart";
import { setCount } from "../redux/reducers/cart1.reducer";
import Top from "./Top";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElBrand, setAnchorElBrand] = React.useState(null);
  const { data } = useGetCategoriesQuery();

  const open = Boolean(anchorElBrand);

  const openCat = Boolean(anchorElUser);

  const dispatch = useDispatch();
  const { brands } = useSelector((state) => state.brandReducer);
  const { cartcount } = useSelector((state) => state.cartReducer);
  const { isLoggedIn } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getCartCount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logged Out.");
  };

  const handleCloseCat = () => {
    setAnchorElUser(null);
  };

  const handleClose = () => {
    setAnchorElBrand(null);
  };

  // useEffect(() => {
  //   dispatch(setCount(cartRes.data.checkoutCount));
  // }, [dispatch, cartRes.data.checkoutCount]);

  // if (isLoading) return <div>Loading..</div>;

  return (
    <>
      {/* <Top /> */}
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
             
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
            <Link to={"/"}>
              <img src={logo} className="h-10" alt="logo" />
            </Link>
            {/* this is the part which shows on mobile devices */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem>
                  <Link to={"/"}>Home</Link>
                </MenuItem>
                <MenuItem>
                  <Button onClick={(e) => setAnchorElUser(e.currentTarget)}>
                    Categories
                  </Button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElUser}
                    open={openCat}
                    onClose={handleCloseCat}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{ maxHeight: "5rem" }}
                  >
                    {data?.map((cat) => (
                      <Link to={`/search?category=${cat.id}`}>
                        <MenuItem>{capital(cat.name)}</MenuItem>
                      </Link>
                    ))}
                  </Menu>
                </MenuItem>

                <MenuItem>
                  <Link to={"/"}>Products</Link>
                </MenuItem>

                <MenuItem>
                  <Button onClick={(e) => setAnchorElBrand(e.currentTarget)}>
                    Brands
                  </Button>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElBrand}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {brands?.map((brand) => (
                      <Link to={`/search?brand=${brand._id}`}>
                        <MenuItem>{capital(brand.name)}</MenuItem>
                      </Link>
                    ))}
                  </Menu>
                </MenuItem>
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Link to={"/"}>
                <Button sx={{ my: 2, color: "white", display: "block" }}>
                  Home
                </Button>
              </Link>

              <Button
                onClick={(e) => setAnchorElUser(e.currentTarget)}
                variant="text"
                sx={{ color: "white" }}
              >
                Categories
                <KeyboardArrowDownIcon />
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorElUser}
                open={openCat}
                onClose={handleCloseCat}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {data?.map((cat) => (
                  <Link
                    className=""
                    to={`/search?category=${encodeURIComponent(cat.id)}`}
                  >
                    <MenuItem>{capital(cat.name)}</MenuItem>
                  </Link>
                ))}
              </Menu>

              <Link to={"/search"}>
                <Button sx={{ my: 2, color: "white", display: "block" }}>
                  Products
                </Button>
              </Link>

              <Button
                onClick={(e) => setAnchorElBrand(e.currentTarget)}
                variant="text"
                sx={{ color: "white" }}
              >
                Brands
                <KeyboardArrowDownIcon />
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorElBrand}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {brands?.map((brand) => (
                  <Link to={`/search?brand=${encodeURIComponent(brand._id)}`}>
                    <MenuItem>{capital(brand.name)}</MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Search />
              {isLoggedIn && (
                <div className="hidden md:block">
                  {
                    <Badge badgeContent={cartcount} color="warning">
                      <Link to="/cart">
                        <ShoppingCartOutlinedIcon />
                      </Link>
                    </Badge>
                  }
                </div>
              )}

              {isLoggedIn && (
                <Link
                  className="hidden md:flex gap-1 mr-3 ml-2"
                  to={"/profile"}
                >
                  <AccountCircleIcon />
                  <Typography className="hidden md:block">
                    My Account
                  </Typography>
                </Link>
              )}

              {!isLoggedIn ? (
                <Link className="hidden md:block" to="/login">
                  Login
                </Link>
              ) : (
                <button
                  className="nostylebtn hidden md:block"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              )}

              <AccountMenu
                logout={handleLogout}
                isActive={isLoggedIn}
                count={cartcount}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
